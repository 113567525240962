import { fromJS, Map as ImmutableMap, List } from 'immutable';
import { TIME_SERIES } from 'reporting-data/constants/configTypes';
import { DESC } from 'reporting-data/constants/sortOrder';
import { AVG } from 'reporting-data/constants/metricTypes';
import { CALL, CONTACTS, CONVERSATIONS, CRM_OBJECT, DEALS, EMAIL_OBJECT, ENGAGEMENT, FEEDBACK_SUBMISSIONS, LEADS, MEETING_EVENT, SEQUENCE_ENROLLMENTS, SUBSCRIPTIONS, TASK, TICKETS, ANALYTICS_FORMS, ANALYTICS_SOURCES, ANALYTICS_ALL_PAGES, ANALYTICS_UTM_CAMPAIGNS, ANALYTICS_UTM_CONTENTS, ANALYTICS_UTM_MEDIUMS, ANALYTICS_UTM_SOURCES, ANALYTICS_UTM_TERMS, ANALYTICS_DEVICE_TYPE_DETAILS, ANALYTICS_GEOLOCATION, ANALYTICS_BROWSER_DETAILS, ANALYTICS_TOPICS, UNIFIED_ADS_CAMPAIGNS, UNIFIED_ADS_NETWORKS } from 'reporting-data/constants/dataTypes';
import { isAttributionReport, isCustomWidgetReport, isRelationalReport } from 'reporting-data/tsTypes/reportTypes';
// @ts-expect-error Untyped dependency
import makeDateRangeByType from 'reporting-data/lib/makeDateRangeByType';
// @ts-expect-error Untyped dependency
import { HUBSPOT_CRM_OBJECTS_BY_ID } from 'reporting-data/crmObjects/utils';
import { AREA, BAR, COLUMN, CUSTOM, DATA_WELL, LINE, TABLE } from 'reporting-data/constants/chartTypes';
import * as unifiedDataTypes from 'reporting-data/constants/dataTypes/unified';
import { has } from 'reporting-data/lib/has';
// @ts-expect-error Untyped dependency
import { mapPropertyToSearchProperty } from 'reporting-data/retrieve/inboundDb/common/mapToSearchProperties';
// @ts-expect-error Untyped dependency
import Widgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
// @ts-expect-error Untyped dependency
import { isCustom } from 'reporting-action-components/lib/report-logic';
import { DEFAULT_DATE_FORMAT } from '../constants/date-format';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { isSubAppReportCollection } from './report-collection-sub-app-data';
import { isSubAppHybridSRVReport } from './report-hybrid-srv-sub-app-data';
import { RANGE_TYPE } from './range';
import { DEAL_CHANGE_HISTORY_RM_GATE, JOURNEY_DATERANGE_PICKER_GATE } from '../constants/gates';
const SINGLE_DEAL_PIPELINE_SUB_APPS = [SUB_APPS.pipelineSnapshots, SUB_APPS.weightedPipelineForecast];
export const DIMENSIONS = {
  COUNTRY: 'COUNTRY',
  CREATOR: 'CREATOR',
  DATE: 'DATE',
  DEAL_STAGE: 'DEAL_STAGE',
  DEAL_CHANGE_TYPE: 'DEAL_CHANGE_TYPE',
  LIFE_CYCLE: 'LIFE_CYCLE',
  OWNER: 'OWNER',
  TEAM: 'TEAM',
  SEQUENCE: 'SEQUENCE',
  SOURCE: 'SOURCE',
  ID: 'ID',
  STAGE: 'STAGE',
  CATEGORY: 'CATEGORY',
  SIZE: 'SIZE',
  TITLE: 'TITLE',
  ASSET_TYPE: 'ASSET_TYPE',
  TOUCHPOINT_TYPE: 'TOUCHPOINT_TYPE',
  CAMPAIGN: 'CAMPAIGN',
  EMAIL: 'EMAIL',
  DEVICE: 'DEVICE',
  SMS: 'SMS',
  SENDING_METHOD: 'SENDING_METHOD',
  CTA: 'CTA',
  OUTCOME: 'OUTCOME',
  PRODUCT_ID: 'PRODUCT_ID',
  PRODUCT_TYPE: 'PRODUCT_TYPE',
  ASSOCIATED_DEAL_TYPE: 'ASSOCIATED_DEAL_TYPE',
  COMPANY: 'COMPANY'
};
const USER_DIMENSIONS = [DIMENSIONS.CREATOR, DIMENSIONS.OWNER];
const DEFAULT_DIMENSION_TO_PROPERTY = {
  [DIMENSIONS.OWNER]: 'hubspot_owner_id',
  [DIMENSIONS.TEAM]: 'hubspot_team_id'
};
const DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY = {
  [DIMENSIONS.ID]: 'breakdown'
};
const DATA_TYPE_DIMENSION_TO_PROPERTY = {
  [CALL]: DEFAULT_DIMENSION_TO_PROPERTY,
  [CONTACTS]: DEFAULT_DIMENSION_TO_PROPERTY,
  [CONVERSATIONS]: {
    [DIMENSIONS.SOURCE]: 'hs_conversation_channel'
  },
  [DEALS]: DEFAULT_DIMENSION_TO_PROPERTY,
  [EMAIL_OBJECT]: DEFAULT_DIMENSION_TO_PROPERTY,
  [ENGAGEMENT]: DEFAULT_DIMENSION_TO_PROPERTY,
  [LEADS]: Object.assign({}, DEFAULT_DIMENSION_TO_PROPERTY, {
    [DIMENSIONS.SOURCE]: 'hs_lead_source'
  }),
  [MEETING_EVENT]: Object.assign({}, DEFAULT_DIMENSION_TO_PROPERTY, {
    [DIMENSIONS.CREATOR]: 'hs_created_by'
  }),
  [SEQUENCE_ENROLLMENTS]: {
    [DIMENSIONS.OWNER]: 'hs_enrolled_by',
    [DIMENSIONS.TEAM]: 'hs_enrolled_by_team_ids',
    [DIMENSIONS.SEQUENCE]: 'hs_sequence_id'
  },
  [TASK]: DEFAULT_DIMENSION_TO_PROPERTY,
  [TICKETS]: DEFAULT_DIMENSION_TO_PROPERTY,
  [ANALYTICS_FORMS]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_ALL_PAGES]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_SOURCES]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_UTM_CAMPAIGNS]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_UTM_CONTENTS]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_UTM_MEDIUMS]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_UTM_SOURCES]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_UTM_TERMS]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_DEVICE_TYPE_DETAILS]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_GEOLOCATION]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_BROWSER_DETAILS]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [ANALYTICS_TOPICS]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY
};
// Sub apps that formerly "magically" held their 2nd dimension constant before we began offering a breakdown editor for them
const SUB_APP_STATIC_BREAKDOWN_TO_PROPERTY = {
  [SUB_APPS.callOutcomes]: {
    [DIMENSIONS.OUTCOME]: 'hs_call_disposition'
  },
  [SUB_APPS.meetingOutcomes]: {
    [DIMENSIONS.OUTCOME]: 'hs_meeting_outcome'
  },
  [SUB_APPS.taskOutcomes]: {
    [DIMENSIONS.OUTCOME]: 'hs_task_status'
  },
  [SUB_APPS.conversations]: {
    [DIMENSIONS.SOURCE]: 'hs_conversation_session_source'
  },
  [SUB_APPS.lossReasons]: {
    [DIMENSIONS.OUTCOME]: 'closed_lost_reason'
  },
  [SUB_APPS.dealsWon]: {
    [DIMENSIONS.OUTCOME]: 'BUCKET_dealProgress'
  },
  [SUB_APPS.dealVelocity]: {
    [DIMENSIONS.OUTCOME]: 'BUCKET_dealProgress'
  },
  [SUB_APPS.supportVolume]: {
    [DIMENSIONS.CATEGORY]: 'hs_ticket_category'
  }
};
const SUB_APP_BREAKDOWN_TO_PROPERTY = Object.assign({
  [SUB_APPS.pipelineSnapshots]: {
    [DIMENSIONS.STAGE]: 'dealstage',
    [DIMENSIONS.CATEGORY]: 'hs_manual_forecast_category',
    [DIMENSIONS.SIZE]: 'amount_in_home_currency'
  },
  [SUB_APPS.leadCapture]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [SUB_APPS.sources]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [SUB_APPS.pages]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [SUB_APPS.utmParameters]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [SUB_APPS.deviceTypes]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [SUB_APPS.geolocation]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [SUB_APPS.browsers]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [SUB_APPS.topicClusters]: DEFAULT_UNIFIED_ANALYTICS_DIMENSION_TO_PROPERTY,
  [SUB_APPS.sms]: {
    [DIMENSIONS.SMS]: 'hs_shortmessage_marketing_sms_id',
    [DIMENSIONS.SENDING_METHOD]: 'hs_shortmessage_sending_method',
    [DIMENSIONS.DATE]: 'date'
  },
  [SUB_APPS.lineItemRevenue]: {
    [DIMENSIONS.PRODUCT_ID]: 'hs_product_id',
    [DIMENSIONS.PRODUCT_TYPE]: 'hs_product_type',
    [DIMENSIONS.ASSOCIATED_DEAL_TYPE]: 'hs_associated_deal_type'
  }
}, SUB_APP_STATIC_BREAKDOWN_TO_PROPERTY);
export const DATA_TYPE_DRILLDOWNS = {
  [LEADS]: {
    [DIMENSIONS.SOURCE]: ['hs_lead_source', 'hs_lead_source_drill_down_1', 'hs_lead_source_drill_down_2']
  }
};
export const SUB_APP_DIMENSION_TO_PROPERTY = Object.assign({
  [SUB_APPS.changeHistoryDealList]: {
    [DIMENSIONS.DEAL_CHANGE_TYPE]: 'raas_historical_change_types'
  },
  [SUB_APPS.dealsCreatedByRep]: {
    [DIMENSIONS.CREATOR]: 'hs_created_by_user_id'
  },
  [SUB_APPS.dealPushAnalysis]: {
    [DIMENSIONS.DEAL_STAGE]: 'dealstage'
  },
  [SUB_APPS.contactInsights]: {
    [DIMENSIONS.COUNTRY]: 'ip_country',
    [DIMENSIONS.LIFE_CYCLE]: 'lifecyclestage',
    [DIMENSIONS.SOURCE]: 'hs_analytics_source'
  },
  [SUB_APPS.attribution]: {
    [DIMENSIONS.TITLE]: 'hs_asset_title',
    [DIMENSIONS.ASSET_TYPE]: 'hs_asset_type',
    [DIMENSIONS.SOURCE]: 'hs_touchpoint_source',
    [DIMENSIONS.TOUCHPOINT_TYPE]: 'hs_touchpoint_type',
    [DIMENSIONS.CAMPAIGN]: 'hs_campaign_name'
  },
  [SUB_APPS.marketingEmails]: {
    [DIMENSIONS.EMAIL]: 'id',
    [DIMENSIONS.CAMPAIGN]: 'emailCampaign',
    [DIMENSIONS.DEVICE]: 'deviceType'
  },
  [SUB_APPS.sms]: {
    [DIMENSIONS.SMS]: 'hs_shortmessage_marketing_sms_id',
    [DIMENSIONS.SENDING_METHOD]: 'hs_shortmessage_sending_method'
  },
  [SUB_APPS.cta]: {
    [DIMENSIONS.CTA]: 'hs_web_interactive_coordinates'
  },
  [SUB_APPS.ticketTimeToClose]: {
    [DIMENSIONS.CATEGORY]: 'hs_ticket_category',
    [DIMENSIONS.SOURCE]: 'source_type',
    [DIMENSIONS.COMPANY]: 'hs_primary_company'
  },
  [SUB_APPS.ticketTimeToRespond]: {
    [DIMENSIONS.CATEGORY]: 'hs_ticket_category',
    [DIMENSIONS.SOURCE]: 'source_type',
    [DIMENSIONS.COMPANY]: 'hs_primary_company'
  }
}, SUB_APP_STATIC_BREAKDOWN_TO_PROPERTY);
export const ACCESSIBLE_TEAM_PROPERTY = 'hs_all_accessible_team_ids';
export const TEAM_GROUPING_TYPE = {
  PRIMARY_TEAM: 'PRIMARY_TEAM',
  ACCESSIBLE_TEAM: 'ACCESSIBLE_TEAM'
};
export const CONVERSATION_CHANNEL_TO_VALUE = {
  livechat: '1000',
  facebook: '1001',
  email: '1002'
};

//push threshold values in milliseconds - backend requires to receive these values in mills
export const PUSH_THRESHOLD_MILLS = {
  DAYS_14: 1209600000,
  DAYS_30: 2592000000,
  DAYS_60: 5184000000,
  DAYS_90: 7776000000
};
export const NO_PIPELINE = '@@NO_PIPELINE@@';
export const isJourneyReport = report => report.has('journeyQuery');
export const isSnowflakeReport = report => isJourneyReport(report) || isRelationalReport(report);
export const updateSort = report => {
  if (isJourneyReport(report)) {
    return report;
  }
  const metricProperty = report.getIn(['config', 'metrics', 0, 'property']);
  const metricType = report.getIn(['config', 'metrics', 0, 'metricTypes', 0]);
  const currentReportSort = report.getIn(['config', 'sort'], List());
  const currentReportSortProperty = currentReportSort.size > 0 ? currentReportSort.getIn([0, 'property']) : null;
  const sortProperty = metricProperty === 'amountInHomeCurrency' && (!report.get('id') || currentReportSortProperty === 'amount_in_home_currency') ? 'amount_in_home_currency' : metricProperty;
  return report.setIn(['config', 'sort'], fromJS([{
    property: sortProperty,
    metricType,
    order: DESC
  }]));
};
export const getDateRange = (rangeType, additionalOptions = {}) => ImmutableMap(makeDateRangeByType(Object.assign({
  rangeType
}, additionalOptions), DEFAULT_DATE_FORMAT));
export const getCustomWidgetType = report => report.getIn(['displayParams', 'customWidget', 'type']);
export const isQuotaReport = report => getCustomWidgetType(report) === Widgets.SALES_QUOTA;
export const isSalesAnalyticsReport = report => report.hasIn(['displayParams', 'salesAnalytics', 'subAppKey']) && !isCustom(report);
export const isSinglePipelineReport = (subAppKey, report) => SINGLE_DEAL_PIPELINE_SUB_APPS.includes(subAppKey) || isJourneyReport(report);
export const getDataType = report => {
  if (isJourneyReport(report)) {
    return report.getIn(['journeyQuery', 'objectQuery', 'table', 'objectTypeId']);
  }
  const configReport = report;
  const dataType = configReport.getIn(['config', 'dataType']);
  return dataType === CRM_OBJECT ? configReport.getIn(['config', 'objectTypeId']) : dataType;
};
export const getTimestampProperty = (report, gates, subAppKey) => {
  if (subAppKey && [SUB_APPS.changeHistoryDealList].includes(subAppKey) && gates.includes(DEAL_CHANGE_HISTORY_RM_GATE)) {
    //The migrated deal change history report uses closedate as the secondary date property
    return 'closedate';
  }
  const dateProperty = report.getIn(['config', 'filters', 'dateRange', 'property']);
  return mapPropertyToSearchProperty(dateProperty, getDataType(report));
};
export const getDataTypeName = report => {
  const dataType = getDataType(report);
  return HUBSPOT_CRM_OBJECTS_BY_ID.get(dataType) || dataType;
};
export const isUnifiedAnalyticsReport = report => {
  const dataType = getDataTypeName(report);
  return has(unifiedDataTypes, dataType);
};
export const hasUsersAndTeamsFilteringSupport = report => ![CONVERSATIONS, FEEDBACK_SUBMISSIONS, SUBSCRIPTIONS].includes(getDataTypeName(report)) && !isUnifiedAnalyticsReport(report) && !isAttributionReport(report) && !isRelationalReport(report);
export const hasFirstDimensionControlSupport = report => {
  if (isSnowflakeReport(report)) {
    return false;
  }
  const configReport = report;
  const isSavedDataWell = configReport.get('id') && configReport.get('chartType') === DATA_WELL;
  return !isSavedDataWell && (!isCustomWidgetReport(configReport) || [Widgets.COMPLETED_ACTIVITIES, Widgets.DEAL_PUSH_ANALYSIS, Widgets.SALES_QUOTA].includes(getCustomWidgetType(configReport))) && ![UNIFIED_ADS_CAMPAIGNS, UNIFIED_ADS_NETWORKS].includes(getDataTypeName(report));
};
export const shouldOfferDateGrouping = (subAppKey, report) => ![SUB_APPS.leadResponseTime, SUB_APPS.changeHistoryDealList].includes(subAppKey) && getCustomWidgetType(report) !== Widgets.DEAL_PUSH_ANALYSIS;
export const getDimensionPropertiesForReport = (subAppKey, report, gates) => {
  const dataType = report && getDataTypeName(report);
  const dataTypeDimensionProperties = DATA_TYPE_DIMENSION_TO_PROPERTY[dataType] || {};
  const subAppDimensionProperties = SUB_APP_DIMENSION_TO_PROPERTY[subAppKey] || {};
  const maybeDateDimension = shouldOfferDateGrouping(subAppKey, report) ? {
    [DIMENSIONS.DATE]: report && getTimestampProperty(report, gates)
  } : {};
  if (getCustomWidgetType(report) === Widgets.PIPELINE_SNAPSHOTS) {
    return maybeDateDimension;
  }
  return Object.assign({}, dataTypeDimensionProperties, subAppDimensionProperties, maybeDateDimension);
};
export const getDimensionProperty = (report, subAppKey, dimension, gates) => getDimensionPropertiesForReport(subAppKey, report, gates)[dimension] || DEFAULT_DIMENSION_TO_PROPERTY[dimension];
export const getBreakdownPropertiesForReport = subAppKey => SUB_APP_BREAKDOWN_TO_PROPERTY[subAppKey] || {};
export const getBreakdownProperty = (subAppKey, breakdown) => getBreakdownPropertiesForReport(subAppKey)[breakdown];
export const hasBreakdownEditor = subAppKey => Object.keys(getBreakdownPropertiesForReport(subAppKey)).length > 0;
export const getUserProperty = (report, subAppKey, grouping, gates) => {
  const userGrouping = USER_DIMENSIONS.includes(grouping) ? grouping : DIMENSIONS.OWNER;
  return getDimensionProperty(report, subAppKey, userGrouping, gates);
};
export const getStaticDimension = (report, subAppKey, grouping) => {
  if (isSnowflakeReport(report) || hasBreakdownEditor(subAppKey)) {
    return null;
  }
  const dimensions = report.getIn(['config', 'dimensions']);
  const sizeWithStaticDimension = grouping ? 2 : 1;
  return dimensions.size === sizeWithStaticDimension ? dimensions.last() : null;
};
export const hasTableVariation = report => report.get('chartType') !== CUSTOM;
export const hasAverageMetric = report => {
  const metrics = report.getIn(['config', 'metrics']);
  const avgMetric = metrics.find(metric => {
    const metricTypes = metric.get('metricTypes') || List();
    return metricTypes.includes(AVG);
  });
  return !!avgMetric;
};
export const isChartReport = report => ![CUSTOM, DATA_WELL, TABLE].includes(report.get('chartType'));
export const isTableReport = report => report.get('chartType') === TABLE;
export const canGroupByAllAccessibleTeams = report => getDataTypeName(report) !== SEQUENCE_ENROLLMENTS && getCustomWidgetType(report) !== Widgets.DEAL_PUSH_ANALYSIS && !isQuotaReport(report);
export const canDisplayStaticGoal = (report, subAppKey) => report.getIn(['config', 'configType']) === TIME_SERIES && [AREA, BAR, COLUMN, LINE].includes(report.get('chartType')) && isSubAppReportCollection(subAppKey);
export const isGroupingRequired = report => isQuotaReport(report) || [Widgets.DEAL_PUSH_ANALYSIS, Widgets.PIPELINE_SNAPSHOTS].includes(getCustomWidgetType(report));
export const isBreakdownRequired = report => [Widgets.PIPELINE_SNAPSHOTS].includes(getCustomWidgetType(report));
export const getDrilldownPropertiesForGrouping = (report, grouping) => {
  const drilldownDimensions = DATA_TYPE_DRILLDOWNS[getDataTypeName(report)] || [];
  return drilldownDimensions[grouping] || [];
};
export const singleReportViewerSupportsSubApp = subAppKey => {
  return [SUB_APPS.attribution, SUB_APPS.sources, SUB_APPS.pages, SUB_APPS.deviceTypes, SUB_APPS.geolocation, SUB_APPS.browsers, SUB_APPS.topicClusters, SUB_APPS.contactInsights, SUB_APPS.utmParameters, SUB_APPS.ads, SUB_APPS.leadCapture, SUB_APPS.marketingEmails].includes(subAppKey);
};
export const getEditorSchemas = report => report.get('editors', List()).toJS();
export const getSpecificEditorSchema = (report, schemaType) => getEditorSchemas(report).find(schema => schema.type === schemaType);
export const unifiedAnalyticsReportIsMissingRequiredFilters = report => {
  const breakdownFilterRequired = isUnifiedAnalyticsReport(report) && report.getIn(['config', 'dimensions', 0]) === 'sessionDate' && report.getIn(['config', 'dimensions', 1]) === 'breakdown';
  const hasBreakdownFilter = report.getIn(['config', 'filters', 'custom'], List()).some(filter => {
    const values = filter.get('values') || List();
    return filter.get('property') === 'filters' && !values.isEmpty();
  });
  return breakdownFilterRequired && !hasBreakdownFilter;
};
export const getIsTabbedSubApp = subAppKey => isSubAppReportCollection(subAppKey) || isSubAppHybridSRVReport(subAppKey);
export const getJourneyDateRangeFromReport = (report, gates) => {
  const journeyReport = report.toJS();
  const {
    propertyFilterOperation,
    dateFrom,
    dateTo
  } = journeyReport.journeyQuery.eventDateInterval;
  const hasJourneyDateRangePicker = gates.includes(JOURNEY_DATERANGE_PICKER_GATE);
  if (hasJourneyDateRangePicker) {
    return propertyFilterOperation ? fromJS({
      propertyFilterOperation
    }) : fromJS({
      dateFrom,
      dateTo
    });
  }
  return propertyFilterOperation ? fromJS({
    rangeType: RANGE_TYPE.ROLLING,
    timeUnitCount: propertyFilterOperation.timeUnitCount
  }) : fromJS({
    rangeType: RANGE_TYPE.CUSTOM,
    dateFrom,
    dateTo
  });
};
export const getJourneyIncludeDropoffsFromReport = report => {
  return report.getIn(['journeyQuery', 'includeDropoffs'], false);
};