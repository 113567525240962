import { createSlice } from '@reduxjs/toolkit';
export const WidgetLayoutsStatus = {
  INITIALIZED: 'INITIALIZED',
  UPDATE: 'UPDATE',
  SAVED: 'SAVED'
};
const initialState = {};
const widgetLayoutSlice = createSlice({
  name: 'widgetLayout',
  initialState,
  reducers: {
    setWidgetLayout(state, action) {
      const {
        newLayout,
        resourceId,
        status = WidgetLayoutsStatus.UPDATE
      } = action.payload;
      state[resourceId] = {
        status,
        widgetLayouts: newLayout
      };
    }
  }
});
export const selectWidgetLayout = resourceId => ({
  widgetLayout
}) => {
  var _widgetLayout$resourc;
  return ((_widgetLayout$resourc = widgetLayout[resourceId]) === null || _widgetLayout$resourc === void 0 ? void 0 : _widgetLayout$resourc.widgetLayouts) || [];
};
export const selectWidgetLayoutStatus = resourceId => ({
  widgetLayout
}) => {
  var _widgetLayout$resourc2;
  return (_widgetLayout$resourc2 = widgetLayout[resourceId]) === null || _widgetLayout$resourc2 === void 0 ? void 0 : _widgetLayout$resourc2.status;
};
export const {
  setWidgetLayout
} = widgetLayoutSlice.actions;
export const removeWidgetLayout = ({
  widgetId,
  resourceId
}) => (dispatch, getState) => {
  const state = getState().widgetLayout;
  if (!state[resourceId] || !state[resourceId].widgetLayouts) {
    return;
  }
  const newLayout = state[resourceId].widgetLayouts.filter(({
    i
  }) => i !== widgetId);
  dispatch(setWidgetLayout({
    newLayout,
    resourceId
  }));
};
export const resetWidgetLayout = ({
  layout
}) => (dispatch, getState) => {
  const resourceId = getState().reportView.resourceId;
  dispatch(setWidgetLayout({
    newLayout: layout,
    resourceId,
    status: WidgetLayoutsStatus.INITIALIZED
  }));
};
export default widgetLayoutSlice.reducer;