import PortalIdParser from 'PortalIdParser';
// DO NOT CHANGE THE ITEMS IN THIS ARRAY AS THEY ARE USED TO DETERMINE THE ORDER OF THE FEATURE LIMITS
export const SORTED_STARTER_FEATURE_LIMIT_NAMES = ['object-lists-dynamic', 'dashboards', 'workflows-contextual-marketing-action-limits', 'custom-property-ui-limit', 'snippets', 'workflows', 'email_sends', 'ads-audience-limit', 'currencies', 'calling-minutes-per-portal', 'calling-minutes-per-seat', 'calling-purchased-phone-numbers-per-portal', 'teams', 'documents', 'templates', 'cms-landing-pages', 'cms-blog-posts', 'sequences', 'crm-index-page-pinned-views-limit', 'crm-object-tags', 'deal-tags', 'cms-pages', 'cms-blog-authors', 'crm-products', 'pipeline-deals', 'pipeline-orders', 'pipeline-service', 'pipeline-tickets', 'cms-blogs-multi-language', 'cms-landing-pages-multi-language', 'cms-pages-multilanguage', 'cms-site-pages-multi-language', 'clip-creator-project-count', 'cms-blog-tags', 'invites-daily', 'facsimile-hubspot-hosted-email-send-daily', 'crm-contacts', 'crm-objects', 'sales-starter-seats', 'service-starter-seats', 'ticket-tags'];
export const SORTED_PRO_FEATURE_LIMIT_NAMES = ['workflows', 'workflows-contextual-marketing-action-limits', 'dashboards', 'custom-reports', 'contacts', 'email_sends', 'calling-minutes-per-portal', 'campaigns', 'social-publish-monthly', 'calling-minutes-per-seat', 'object-lists-dynamic', 'pipeline-deals', 'whatsapp-conversations', 'pipeline-orders', 'knowledge-articles', 'pipeline-leads', 'crm-pipelines', 'ads-audience-limit', 'social-connected-channels', 'ads-accounts', 'pipeline-tickets', 'cms-site-pages', 'cms-blogs', 'transcript-hours', 'feedback-ces-surveys', 'automation-journey-actions-limit', 'automation-journeys-limit', 'calling-purchased-phone-numbers-per-portal', 'campaign-asset-association-limit', 'cms-site-pages-deprecated', 'crm-object-tags', 'embeddable-videos', 'feedback-csat-surveys', 'feedback-custom-surveys', 'feedback-nps-surveys', 'knowledge-bases', 'lead-score-configurations', 'pipeline-service', 'playbooks', 'teams', 'user-groups'];
export const FEATURE_LIMIT_LINK_MAP = {
  'object-lists-dynamic': {
    linkedProductNameKey: 'lists',
    linkUrl: `contacts/${PortalIdParser.get()}/objectLists/views/all?count=25`
  },
  dashboards: {
    linkedProductNameKey: 'dashboards',
    linkUrl: `reports-dashboard/${PortalIdParser.get()}/view`
  },
  'workflows-contextual-marketing-action-limits': {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'custom-property-ui-limit': {
    linkedProductNameKey: 'properties',
    linkUrl: `property-settings/${PortalIdParser.get()}/properties?type=0-1`
  },
  snippets: {
    linkedProductNameKey: 'snippets',
    linkUrl: `snippets/${PortalIdParser.get()}`
  },
  contacts: {
    linkedProductNameKey: 'contacts',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-1/views/all/list`
  },
  workflows: {
    linkedProductNameKey: 'workflows',
    linkUrl: `workflows/${PortalIdParser.get()}/view/default`
  },
  email_sends: {
    linkedProductNameKey: 'emails',
    linkUrl: `email/${PortalIdParser.get()}/manage/state/all`
  },
  'ads-audience-limit': {
    linkedProductNameKey: 'ads',
    linkUrl: `ads/${PortalIdParser.get()}`
  },
  currencies: {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'calling-minutes-per-portal': {
    linkedProductNameKey: 'calls',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-48/views/recorded_calls/list`
  },
  'calling-minutes-per-seat': {
    linkedProductNameKey: 'calls',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-48/views/recorded_calls/list`
  },
  'calling-purchased-phone-numbers-per-portal': {
    linkedProductNameKey: 'phoneNumbers',
    linkUrl: `settings/${PortalIdParser.get()}/calling/numbers`
  },
  teams: {
    linkedProductNameKey: 'teams',
    linkUrl: `settings/${PortalIdParser.get()}/users/teams`
  },
  documents: {
    linkedProductNameKey: 'documents',
    linkUrl: `documents/${PortalIdParser.get()}`
  },
  templates: {
    linkedProductNameKey: 'templates',
    linkUrl: `templates/${PortalIdParser.get()}`
  },
  'cms-landing-pages': {
    linkedProductNameKey: 'landingPages',
    linkUrl: `website/${PortalIdParser.get()}/pages/landing`
  },
  'cms-blog-posts': {
    linkedProductNameKey: 'blogPosts',
    linkUrl: `website/${PortalIdParser.get()}/blog/posts`
  },
  sequences: {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'crm-index-page-pinned-views-limit': {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'crm-object-tags': {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'deal-tags': {
    linkedProductNameKey: 'tags',
    linkUrl: `pipelines-settings/${PortalIdParser.get()}/object/0-3/tags`
  },
  'cms-pages': {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'cms-blog-authors': {
    linkedProductNameKey: 'blogPosts',
    linkUrl: `website/${PortalIdParser.get()}/blog/posts`
  },
  'crm-products': {
    linkedProductNameKey: 'products',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-7/views/all/list`
  },
  'pipeline-deals': {
    linkedProductNameKey: 'deals',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-3/views/all/list`
  },
  'pipeline-orders': {
    linkedProductNameKey: 'orders',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-123/views/all/list`
  },
  'pipeline-service': {
    linkedProductNameKey: 'pipeline-service',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-5/views/all/list`
  },
  'pipeline-tickets': {
    linkedProductNameKey: 'tickets',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-5/views/all/list`
  },
  'cms-blogs-multi-language': {
    linkedProductNameKey: 'blogPosts',
    linkUrl: `website/${PortalIdParser.get()}/blog/posts`
  },
  'cms-landing-pages-multi-language': {
    linkedProductNameKey: 'landingPages',
    linkUrl: `website/${PortalIdParser.get()}/pages/landing`
  },
  'cms-pages-multilanguage': {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'cms-site-pages-multi-language': {
    linkedProductNameKey: 'websitePages',
    linkUrl: `website/${PortalIdParser.get()}/pages/site`
  },
  'clip-creator-project-count': {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'cms-blog-tags': {
    linkedProductNameKey: 'blogPosts',
    linkUrl: `website/${PortalIdParser.get()}/blog/posts`
  },
  'invites-daily': {
    linkedProductNameKey: null,
    linkUrl: null
  },
  'facsimile-hubspot-hosted-email-send-daily': {
    linkedProductNameKey: 'emails',
    linkUrl: `email/${PortalIdParser.get()}/manage/state/all`
  },
  'crm-contacts': {
    linkedProductNameKey: 'contacts',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-1/views/all/list`
  },
  'crm-objects': {
    linkedProductNameKey: 'objects',
    linkUrl: `object-library/${PortalIdParser.get()}`
  },
  'sales-starter-seats': {
    linkedProductNameKey: 'seats',
    linkUrl: `settings/${PortalIdParser.get()}/users/seats`
  },
  'service-starter-seats': {
    linkedProductNameKey: 'seats',
    linkUrl: `settings/${PortalIdParser.get()}/users/seats`
  },
  'ticket-tags': {
    linkedProductNameKey: 'tags',
    linkUrl: `pipelines-settings/${PortalIdParser.get()}/object/0-5/0/tags`
  },
  'custom-reports': {
    linkedProductNameKey: 'custom-reports',
    linkUrl: `reports-list/${PortalIdParser.get()}?filterCustomReports=true`
  },
  campaigns: {
    linkedProductNameKey: 'campaigns',
    linkUrl: `marketing/${PortalIdParser.get()}/campaigns/views/all`
  },
  'social-publish-monthly': {
    linkedProductNameKey: 'social-publish-monthly',
    linkUrl: `social/${PortalIdParser.get()}/manage`
  },
  'whatsapp-conversations': {
    linkedProductNameKey: 'whatsapp-conversations',
    linkUrl: `ecosystem/${PortalIdParser.get()}/marketplace/apps/whatsapp-1311356`
  },
  'knowledge-articles': {
    linkedProductNameKey: 'knowledge-articles',
    linkUrl: `knowledge/${PortalIdParser.get()}`
  },
  'pipeline-leads': {
    linkedProductNameKey: 'pipeline-leads',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-3/views/all/list`
  },
  'crm-pipelines': {
    linkedProductNameKey: 'crm-pipelines',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-3/views/all/list`
  },
  'social-connected-channels': {
    linkedProductNameKey: 'social-connected-channels',
    linkUrl: `social/${PortalIdParser.get()}/welcome`
  },
  'ads-accounts': {
    linkedProductNameKey: 'ads-accounts',
    linkUrl: `ads/${PortalIdParser.get()}`
  },
  'cms-site-pages': {
    linkedProductNameKey: 'cms-site-pages',
    linkUrl: `website/${PortalIdParser.get()}/pages/site`
  },
  'cms-blogs': {
    linkedProductNameKey: 'cms-blogs',
    linkUrl: `website/${PortalIdParser.get()}/blog/posts`
  },
  'transcript-hours': {
    linkedProductNameKey: 'transcript-hours',
    linkUrl: `contacts/${PortalIdParser.get()}/objects/0-48`
  },
  'feedback-ces-surveys': {
    linkedProductNameKey: 'feedback-ces-surveys',
    linkUrl: `feedback/${PortalIdParser.get()}/dashboard/manage/surveys/views/all`
  },
  'automation-journey-actions-limit': {
    linkedProductNameKey: 'automation-journey-actions-limit',
    linkUrl: `workflows/${PortalIdParser.get()}/view/default`
  },
  'automation-journeys-limit': {
    linkedProductNameKey: 'automation-journeys-limit',
    linkUrl: `workflows/${PortalIdParser.get()}/view/default`
  },
  'campaign-asset-association-limit': {
    linkedProductNameKey: 'campaign-asset-association-limit',
    linkUrl: `marketing/${PortalIdParser.get()}/campaigns/views/all`
  },
  'cms-site-pages-deprecated': {
    linkedProductNameKey: 'cms-site-pages-deprecated',
    linkUrl: `website/${PortalIdParser.get()}/pages/site`
  },
  'embeddable-videos': {
    linkedProductNameKey: 'embeddable-videos',
    linkUrl: `files/${PortalIdParser.get()}`
  },
  'feedback-csat-surveys': {
    linkedProductNameKey: 'feedback-csat-surveys',
    linkUrl: `feedback/${PortalIdParser.get()}/dashboard/manage/surveys/views/all`
  },
  'feedback-custom-surveys': {
    linkedProductNameKey: 'feedback-custom-surveys',
    linkUrl: `feedback/${PortalIdParser.get()}/dashboard/manage/surveys/views/all`
  },
  'feedback-nps-surveys': {
    linkedProductNameKey: 'feedback-nps-surveys',
    linkUrl: `feedback/${PortalIdParser.get()}/dashboard/manage/surveys/views/all`
  },
  'knowledge-bases': {
    linkedProductNameKey: 'knowledge-bases',
    linkUrl: `knowledge/${PortalIdParser.get()}`
  },
  'lead-score-configurations': {
    linkedProductNameKey: 'lead-score-configurations',
    linkUrl: `lead-scoring/${PortalIdParser.get()}/manage`
  },
  playbooks: {
    linkedProductNameKey: 'playbooks',
    linkUrl: `playbooks/${PortalIdParser.get()}`
  },
  'user-groups': {
    linkedProductNameKey: 'user-groups',
    linkUrl: `settings/${PortalIdParser.get()}/users`
  }
};