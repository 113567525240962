export const TaxIDValidationStatuses = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED'
};
export const TaxIDTypes = {
  VAT: 'VAT',
  NIT: 'NIT',
  RUT: 'RUT',
  NDS: 'NDS',
  PST: 'PST',
  THAILAND_VAT: 'THAILAND_VAT'
};