import { useSelector } from 'react-redux';
import { useDataSourceMetaWithLoading } from 'platform-dashboard-ui/hooks/use-data-source-metadata';
import { useDataSourceProperties } from 'platform-dashboard-ui/hooks/use-data-source-properties';
import { useReportDataSourceUsages } from './use-report-data-source-usages';
import { useSavedDashboardFiltersPanelContext } from '../context';
import { getDataSourceKeysForFilters } from '../../../../../../ducks/applied-dashboard-filters/utils';
import { selectSavedFilterSets } from '../../../../../../ducks/savedDashboardFilters';
const useDataSources = dataSourceKeys => {
  const {
    isLoading: isDataSourcesMetadataLoading,
    dataSourceMetaData
  } = useDataSourceMetaWithLoading(dataSourceKeys);
  const {
    isLoading: isDataSourcesPropertiesLoading,
    propertiesByDataSource
  } = useDataSourceProperties(dataSourceKeys);
  const isLoading = isDataSourcesMetadataLoading || isDataSourcesPropertiesLoading;
  return {
    dataSourceKeys,
    dataSourcesMetadata: dataSourceMetaData,
    dataSourcesProperties: propertiesByDataSource,
    isLoading
  };
};
export const useReportDataSources = () => {
  const {
    dashboardId
  } = useSavedDashboardFiltersPanelContext();
  const reportDataSourceUsages = useReportDataSourceUsages();
  const savedFilterSets = useSelector(selectSavedFilterSets(dashboardId));
  const dataSourceKeys = savedFilterSets.reduce((dataSourceKeySet, savedFilterSet) => savedFilterSet.filters.reduce((nextDataSourceKeySet, filter) => nextDataSourceKeySet.add(filter.dataSource), dataSourceKeySet), new Set(Object.keys(reportDataSourceUsages)));
  return useDataSources(Array.from(dataSourceKeys));
};
export const useFiltersDataSources = filters => {
  return useDataSources(getDataSourceKeysForFilters(filters));
};
export const useDraftFiltersDataSources = () => {
  const {
    draftFilterSetFilters
  } = useSavedDashboardFiltersPanelContext();
  return useFiltersDataSources(draftFilterSetFilters);
};