import { onResponseError } from 'hub-http/middlewares/core';
import { logError } from '../utils/logError';
import { ApiError } from '../utils/ApiError';
export const CORRELATION_ID_HEADER = 'x-hubspot-correlation-id';
export const sendRavenOnResponseError = (options = {}) =>
// eslint-disable-next-line @typescript-eslint/no-misused-promises
onResponseError(error => {
  var _options$ravenOptions, _error$headers$CORREL, _error$headers;
  const ravenOptions = (_options$ravenOptions = options === null || options === void 0 ? void 0 : options.ravenOptions) !== null && _options$ravenOptions !== void 0 ? _options$ravenOptions : {};
  const {
    enabled = true,
    tags,
    extra = {}
  } = ravenOptions;
  const correlationId = (_error$headers$CORREL = error === null || error === void 0 || (_error$headers = error.headers) === null || _error$headers === void 0 ? void 0 : _error$headers[CORRELATION_ID_HEADER]) !== null && _error$headers$CORREL !== void 0 ? _error$headers$CORREL : '';
  if (enabled) {
    logError({
      error: ApiError.fromRestError({
        request: options,
        error
      }),
      tags,
      extra,
      correlationId,
      fingerprint: ['{{ message }}']
    });
  }
  return Promise.reject(error);
})(options);