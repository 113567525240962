import { List } from 'immutable';
import { isConfigReport, isCustomWidgetReport, isJourneyReport } from '../../tsTypes/reportTypes';
import { getDimensions, getMetrics } from '../configReportGetters';
export const configReportHasAtLeastOneDimension = report => {
  if (report && isConfigReport(report)) {
    const metrics = getMetrics(report);
    const reportDimensions = getDimensions(report);
    if (reportDimensions.size >= 1 && metrics.size > 0) {
      return true;
    }
  }
  return false;
};
export const configReportValidForNonSeriesTransformation = report => {
  if (report && isConfigReport(report) && !isCustomWidgetReport(report)) {
    const metrics = getMetrics(report);
    const reportDimensions = getDimensions(report);
    if ((reportDimensions.size === 0 && metrics.size > 0 || reportDimensions.size === 1 && metrics.size === 1) && reportDimensions.size < 2) {
      return true;
    }
  }
  return false;
};
const hasBranchingStage = journeyReport => {
  const stages = journeyReport.getIn(['journeyQuery', 'stages']);
  return stages && stages.filter(stage => stage.get('steps', List()).size > 1).size > 0;
};
export const isReportValidForColumnJourneyTransform = report => {
  if (isJourneyReport(report)) {
    const journeyReportStages = report.getIn(['journeyQuery', 'stages'], []);
    const hasBranching = hasBranchingStage(report);
    if (hasBranching) return false;
    const journeyReportHasOptionalStages = !!journeyReportStages.find(stage => stage.get('optional'));
    const journeyReportHasBreakdownStages = journeyReportStages.find(stage => stage.get('breakdown') || stage.get('pathfinder'));
    if (journeyReportHasBreakdownStages) return false;

    // am hiding column report transform optionally until this task completed: https://issues.hubspotcentral.com/browse/SR-9
    if (!journeyReportHasOptionalStages) {
      return true;
    }
  }
  return false;
};
export const isReportValidForFunnelJourneyTransform = report => {
  if (!isJourneyReport(report)) return false;
  const hasBranching = hasBranchingStage(report);
  if (hasBranching) return false;
  const journeyReportStages = report.getIn(['journeyQuery', 'stages'], []);
  const journeyReportHasBreakdownStages = journeyReportStages.find(stage => stage.get('breakdown') || stage.get('pathfinder'));
  if (journeyReportHasBreakdownStages) return false;
  return true;
};