import { DefaultFieldKeys } from '../Constants';
// @ts-expect-error untyped dep
import EmailAddressPattern from 'PatternValidationJS/patterns/EmailAddress';
import { UsageTracker } from '../../UsageTracker';
export function getFormFieldDefaults(formFields) {
  return formFields.filter(formField => formField !== null && formField !== undefined).reduce((defaultValues, field) => {
    const fieldFunction = field;
    if (fieldFunction.key) {
      defaultValues[fieldFunction.key] = fieldFunction.defaultValue;
    }
    return defaultValues;
  }, {});
}
export function getEmailValidationState(formState) {
  const emailValue = formState[DefaultFieldKeys.EMAIL];
  const email = typeof emailValue === 'string' ? emailValue.trim() : emailValue;
  if (!EmailAddressPattern.test(email)) {
    return 'INVALID';
  }
  return 'VALID';
}
export function getExportReason(xhr) {
  if (xhr.status === 0) {
    return 'NO_NETWORK';
  }
  if (xhr.response) {
    try {
      if (JSON.parse(xhr.response).errorType === 'FREE_PORTAL_EXPORTS_LIMIT') {
        return 'FREE_PORTAL_EXPORTS_LIMIT';
      }
      if (JSON.parse(xhr.response).errorType === 'PAID_PORTAL_EXPORTS_LIMIT') {
        return 'PAID_PORTAL_EXPORTS_LIMIT';
      }
      if (JSON.parse(xhr.response).message === 'Portal is blocked from performing exports') {
        return 'BLOCKED_PORTAL';
      }
    } catch (e) {
      // ignore
    }
  }
  return 'UNKNOWN';
}

// Primarily used to track for CrmExportDialog — other instances of ExportDialog may not structure their options in the same way
export function trackCrmExportOptions(options) {
  var _languageAndFormat$fo, _languageAndFormat$la, _crmColumnsOptions$ex;
  const languageAndFormat = options['LANGUAGE_AND_FORMAT'];
  const crmColumnsOptions = options['EXPORT_COLUMNS_OPTIONS'];
  const isEnabled = typeof (crmColumnsOptions === null || crmColumnsOptions === void 0 ? void 0 : crmColumnsOptions.includeAdditional) === 'boolean' ? String(crmColumnsOptions === null || crmColumnsOptions === void 0 ? void 0 : crmColumnsOptions.includeAdditional) : '';
  const associationCount = (crmColumnsOptions === null || crmColumnsOptions === void 0 ? void 0 : crmColumnsOptions.associationLimitOverride) === true ? 'all' : (crmColumnsOptions === null || crmColumnsOptions === void 0 ? void 0 : crmColumnsOptions.associationLimitOverride) === false ? 'up to 1k' : '';
  UsageTracker.track('exportBtnClick', {
    fileFormat: (_languageAndFormat$fo = languageAndFormat === null || languageAndFormat === void 0 ? void 0 : languageAndFormat.format) !== null && _languageAndFormat$fo !== void 0 ? _languageAndFormat$fo : '',
    requestedLanguage: (_languageAndFormat$la = languageAndFormat === null || languageAndFormat === void 0 ? void 0 : languageAndFormat.language) !== null && _languageAndFormat$la !== void 0 ? _languageAndFormat$la : '',
    isEnabled,
    columns: (_crmColumnsOptions$ex = crmColumnsOptions === null || crmColumnsOptions === void 0 ? void 0 : crmColumnsOptions.exportColumns) !== null && _crmColumnsOptions$ex !== void 0 ? _crmColumnsOptions$ex : '',
    associationCount
  });
}