module.exports = {
  "pageview": {
    "name": "ExportDialog",
    "class": "view",
    "properties": {
      "action": {
        "type": "string",
        "isOptional": false
      }
    },
    "namespace": "salesimportui"
  },
  "exportBtnClick": {
    "name": "ExportDialog",
    "class": "interaction",
    "properties": {
      "fileFormat": {
        "type": "string",
        "isOptional": false
      },
      "requestedLanguage": {
        "type": "string",
        "isOptional": false
      },
      "isEnabled": {
        "type": "string",
        "isOptional": false
      },
      "columns": {
        "type": "string",
        "isOptional": false
      },
      "associationCount": {
        "type": "string",
        "isOptional": false
      }
    },
    "namespace": "salesimportui"
  }
};