import { createTracker } from 'usage-tracker';
import events from '../../events.yaml';
export const resurrectionBannerTracker = createTracker({
  events,
  properties: {
    namespace: 'growth-feature-usage-components'
  }
});
export const setExperimentCohortInTracker = experimentCohort => resurrectionBannerTracker.setProperties({
  experimentCohort
});
export const trackViewResurrectionBanner = featureLimitName => resurrectionBannerTracker.track('resurrectionBannerInteraction', {
  action: 'viewed banner',
  featureLimitName
});
export const trackDismissResurrectionBanner = featureLimitName => resurrectionBannerTracker.track('resurrectionBannerInteraction', {
  action: 'dismissed banner',
  featureLimitName
});
export const trackTurnOnAutoRenew = featureLimitName => resurrectionBannerTracker.track('resurrectionBannerInteraction', {
  action: 'clicked on turn on auto-renew',
  featureLimitName
});
export const trackAutoRenewSuccess = featureLimitName => resurrectionBannerTracker.track('resurrectionBannerInteraction', {
  action: 'auto-renew successful',
  featureLimitName
});
export const trackAutoRenewError = featureLimitName => resurrectionBannerTracker.track('resurrectionBannerInteraction', {
  action: 'auto-renew error',
  featureLimitName
});