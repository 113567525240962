'use es6';

import { createRequestAction } from '../request/create-request-action';
import { FETCH, PUT } from '../request/request-type';
import * as PermissionDao from './permission-dao';
export const rootNameSpace = 'permission';
export const dashboardConfigNamespace = 'dashboard.config';
export const dashboardSentenceNamespace = 'dashboard.sentence';
export const reportConfigNamespace = 'report.config';
export const reportSentenceNamespace = 'report.sentence';
export const fetchDashboardSentenceSingle = createRequestAction(`${rootNameSpace}.${dashboardSentenceNamespace}.single.${FETCH}`, PermissionDao.fetchDashboardSentenceSingle);
export const fetchDashboardSentenceMulti = createRequestAction(`${rootNameSpace}.${dashboardSentenceNamespace}.multi.${FETCH}`, PermissionDao.fetchDashboardSentenceMulti);
export const fetchDashboardConfigSingle = createRequestAction(`${rootNameSpace}.${dashboardConfigNamespace}.single.${FETCH}`, PermissionDao.fetchDashboardConfigSingle);
export const putDashboardConfigSingle = createRequestAction(`${rootNameSpace}.${dashboardConfigNamespace}.single.${PUT}`, PermissionDao.putDashboardConfigSingle);
export const fetchDashboardConfigSingleGranular = createRequestAction(`${rootNameSpace}.${dashboardConfigNamespace}.single.${FETCH}`, PermissionDao.fetchDashboardConfigSingleGranular);
export const putDashboardConfigSingleGranular = createRequestAction(`${rootNameSpace}.${dashboardConfigNamespace}.single.${PUT}`, PermissionDao.putDashboardConfigSingleGranular);
export const fetchReportSentenceSingle = createRequestAction(`${rootNameSpace}.${reportSentenceNamespace}.single.${FETCH}`, PermissionDao.fetchReportSentenceSingle);
export const fetchReportSentenceMulti = createRequestAction(`${rootNameSpace}.${reportSentenceNamespace}.multi.${FETCH}`, PermissionDao.fetchReportSentenceMulti);
export const fetchReportConfigSingle = createRequestAction(`${rootNameSpace}.${reportConfigNamespace}.single.${FETCH}`, PermissionDao.fetchReportConfigSingle);
export const putReportConfigSingle = createRequestAction(`${rootNameSpace}.${reportConfigNamespace}.single.${PUT}`, PermissionDao.putReportConfigSingle);