export const DOCUMENT_TYPES = {
  LEGACYING_MIGRATION: 'LEGACYING_MIGRATION',
  CREDIT: 'CREDIT',
  INVOICE: 'INVOICE',
  ORDER_PDF: 'ORDER_PDF',
  PAYMENT: 'PAYMENT',
  REFUND: 'REFUND',
  BANK: 'BANK',
  BANK_ACCOUNT_AUTHORIZATION: 'BANK_ACCOUNT_AUTHORIZATION',
  COMPANY_NAME_CHANGE: 'COMPANY_NAME_CHANGE',
  CONTRACT: 'CONTRACT',
  REDUCED_GROWTH_STARTER_RENEWAL: 'REDUCED_GROWTH_STARTER_RENEWAL',
  TAX: 'TAX',
  UPLOADED_DOCUMENT: 'UPLOADED_DOCUMENT'
};