import { List as ImmutableList } from 'immutable';
import toJS from '../lib/toJS';
import { fromJS } from 'immutable';
import { post } from '../request/http';
// @ts-expect-error Untyped dependency
import { applyReportFixes } from '../v2/reportingApi/fixes';
export const exportDatasetViaRaasApi = (report, exportOptions) => {
  const reportForExport = fromJS(applyReportFixes(report)).get('report');
  const requestBody = {
    reportId: reportForExport.get('id'),
    exportFormat: exportOptions.format,
    exportName: exportOptions.title,
    config: reportForExport.get('config'),
    displayParams: reportForExport.get('displayParams'),
    dataColumns: reportForExport.getIn(['displayParams', 'dataColumns'], []),
    reportConfigs: reportForExport.get('reportConfigs'),
    excludedProperties: reportForExport.getIn(['displayParams', 'hidePropertiesOnExport'], ImmutableList())
  };
  return post('reporting-platform/v1/config/export', {
    data: requestBody
  }).then(toJS).catch(error => {
    console.error(error);
    throw error;
  });
};