'use es6';

import { List, fromJS } from 'immutable';
import I18n from 'I18n';
import cached from '../lib/cached';
import { Promise } from '../lib/promise';
import { MissingPropertiesException } from '../exceptions';
import dataTypeProperties from './data-type';
import { isSupportedCrmObject, getCrmObjectPropertyGroups, getCrmObjectName, getCrmObjectPluralName } from '../crmObjects/utils';
import { convertDataTypeToObjectTypeId } from '../crmObjects/tsUtils';
import { customObjectCountProperty } from './partial/count-property';
import { isExternalDataType } from '../report/externalReportGetters';
import getProperties from './index';
import { getAndValidatePropertyGroups, isPropertyProvidedByRaasMetadataStore } from './reportingApi/validateAndFetch';
import { fetchPropertyGroupsFromRaasApi, isUngatedToSkipPropertyValidation } from './reportingApi/private/utils';
import { fetchDataSystemMetadata } from '../v2/externalDataSystemMetadata/fetchMetadata';
import { isSupportedUnifiedEventQLDataType } from '../unifiedEventQL/utils';
const assignPropertyGroups = propertyGroups => {
  return propertyGroups.map(group => group.update('properties', properties => properties.map(property => {
    return property.set('groupName', group.get('name'));
  })));
};
const mergeGroups = propertyGroups => {
  return propertyGroups.reduce((acc, propertyGroup) => {
    const index = acc.findIndex(group => group.get('name') === propertyGroup.get('name'));
    if (index === -1) {
      acc = acc.push(propertyGroup);
    } else {
      const group = acc.get(index);
      const updatedGroup = group.update('properties', properties => {
        return properties.concat(propertyGroup.get('properties'));
      });
      acc = acc.set(index, updatedGroup);
    }
    return acc;
  }, List());
};
const getPropertyGroups = dataType => {
  const objectTypeId = convertDataTypeToObjectTypeId(dataType);
  return Promise.all([isPropertyProvidedByRaasMetadataStore(objectTypeId), isUngatedToSkipPropertyValidation()]).then(([alreadyValidatedPropertyUseBeProps, ungatedToSkipPropertyValidation]) => {
    if (alreadyValidatedPropertyUseBeProps || isSupportedUnifiedEventQLDataType(dataType)) {
      return fetchPropertyGroupsFromRaasApi(objectTypeId);
    }
    if (isSupportedCrmObject(dataType)) {
      if (ungatedToSkipPropertyValidation) {
        return fetchPropertyGroupsFromRaasApi(objectTypeId);
      }
      const fetchPropertyGroupsPromise = Promise.all([getCrmObjectPropertyGroups(dataType), getCrmObjectName(dataType), getCrmObjectPluralName(dataType)]).then(([propertyGroups, name, pluralName]) => {
        const index = propertyGroups.findIndex(group => group.get('name') === `${name.toLowerCase()}_information`);
        return propertyGroups.updateIn([index, 'properties'], List(), properties => properties.unshift(customObjectCountProperty(pluralName).get('count')));
      });
      return getAndValidatePropertyGroups(dataType, fetchPropertyGroupsPromise);
    } else if (dataTypeProperties.has(dataType)) {
      if (ungatedToSkipPropertyValidation) {
        return fetchPropertyGroupsFromRaasApi(objectTypeId);
      }
      const fetchPropertyGroupsPromise = dataTypeProperties.get(dataType).getPropertyGroups().then(mergeGroups).then(assignPropertyGroups);
      return getAndValidatePropertyGroups(dataType, fetchPropertyGroupsPromise);
    } else if (isExternalDataType(dataType)) {
      return Promise.all([fetchDataSystemMetadata(dataType), getProperties(dataType)]).then(([dataSystemMetadata, properties]) => {
        // External dataTypes have flat property list and no concept of property group.
        const dataSystemLabel = dataSystemMetadata ? dataSystemMetadata.displayLabel.value : dataType;
        const filterName = I18n.text('reporting-data.groups.externalDataSystem', {
          dataSystemLabel
        });
        return fromJS([{
          name: filterName,
          displayName: filterName,
          properties: properties.get(dataType).toList()
        }]);
      });
    } else {
      return Promise.reject(new MissingPropertiesException(dataType));
    }
  });
};
export const __TESTABLE__ = {
  getPropertyGroups
};
export default cached('default', getPropertyGroups);