import { fetchDataSourcesMetadata, selectDataSourcesMetadataStatus, selectDataSourcesMetadataWithUniqueLabels } from '../ducks/dataSourceMetadataSlice';
import { usePlatformDashboardDispatch, usePlatformDashboardSelector } from '../store';
import { useEffect } from 'react';
import { RequestState } from '../filter/requests/request-state';
/** @deprecated use `useDataSourceMetaWithLoading` instead as it returns a boolean `isLoading` indicator */
export const useDataSourceMeta = dataSourceIds => {
  const dataSourceMetaData = usePlatformDashboardSelector(selectDataSourcesMetadataWithUniqueLabels(dataSourceIds));
  const dataSourceMetadataStatus = usePlatformDashboardSelector(selectDataSourcesMetadataStatus(dataSourceIds));
  const dispatch = usePlatformDashboardDispatch();
  useEffect(() => {
    if (dataSourceMetadataStatus === RequestState.UNINITIALIZED) {
      dispatch(fetchDataSourcesMetadata(dataSourceIds));
    }
  }, [dataSourceIds, dataSourceMetadataStatus, dispatch]);
  return dataSourceMetaData || {};
};
export const useDataSourceMetaWithLoading = dataSourceIds => {
  const dataSourceMetaData = useDataSourceMeta(dataSourceIds);
  const dataSourceMetadataStatus = usePlatformDashboardSelector(selectDataSourcesMetadataStatus(dataSourceIds));
  const isUninitialized = dataSourceMetadataStatus === RequestState.UNINITIALIZED;
  const isLoading = dataSourceMetadataStatus === RequestState.PENDING || isUninitialized;
  const isResolved = !isLoading;
  const hasFailed = dataSourceMetadataStatus === RequestState.FAILED;
  return {
    dataSourceMetaData,
    isLoading,
    isUninitialized,
    isResolved,
    hasFailed
  };
};