import { registerQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../clients/hubHttpWithRaven';
import { stringify } from 'hub-http/helpers/params';
import { BILLING_EVENT_TYPES_LIST } from '../../constants/billingEventTypes';
export const GET_IS_BILLING_HISTORY_ELIGIBILE = registerQuery({
  args: [],
  fieldName: 'billingHistoryEligibility',
  fetcher() {
    return httpClientWithRaven.get(`billing-events-index/v1/is-eligible?${stringify({
      eventType: BILLING_EVENT_TYPES_LIST
    })}`);
  }
});