import { useQuery } from 'data-fetching-client';
import { GET_DELINQUENCY_STATUS } from '../api/queries/delinquencyStatus';
import { getIn } from '../utils/getIn';
import { useThrowIfNoDataFetchingClient } from './useThrowIfNoDataFetchingClient';
export const useGetDelinquencyStatus = ({
  skip
} = {}) => {
  useThrowIfNoDataFetchingClient();
  const {
    data,
    loading,
    error,
    refetch
  } = useQuery(GET_DELINQUENCY_STATUS, {
    skip,
    refetchWritePolicy: 'overwrite'
  });
  return {
    customerDelinquencyStatus: getIn(['delinquencyStatus', 'customerDelinquencyStatus'], data),
    customerDelinquentInvoiceIds: getIn(['delinquencyStatus', 'customerDelinquentInvoiceIds'], data),
    loading,
    error,
    refetch
  };
};