import Raven from 'raven-js';
import { ApiError } from './ApiError';
import { copyObject } from './copyObject';
const excludedHttpStatusPatterns = [/^0$/, /401/, /404/, /5\d\d/];
const isNullish = val => val === null || val === undefined;
const _ignoreRavenErrorsByStatus = error => {
  var _error$networkError;
  let errorStatus = error === null || error === void 0 ? void 0 : error.status;
  errorStatus = isNullish(errorStatus) ? error === null || error === void 0 ? void 0 : error.statusCode : errorStatus;
  errorStatus = isNullish(errorStatus) ? error === null || error === void 0 || (_error$networkError = error.networkError) === null || _error$networkError === void 0 || (_error$networkError = _error$networkError.cause) === null || _error$networkError === void 0 ? void 0 : _error$networkError.status : errorStatus;
  const isExcludedHttpErrors = excludedHttpStatusPatterns.some(pattern => pattern.test(errorStatus));
  return isExcludedHttpErrors;
};
const _ignoreRavenErrorsByType = error => {
  var _error$networkError2;
  const errorType = (error === null || error === void 0 ? void 0 : error.errorCode) || {};
  const errorCause = (error === null || error === void 0 || (_error$networkError2 = error.networkError) === null || _error$networkError2 === void 0 ? void 0 : _error$networkError2.cause) || {};
  const {
    timeout,
    offline
  } = errorCause;
  const isOffline = navigator ? !navigator.onLine : false;
  return errorType === 'TIMEOUT' || errorType === 'NETWORKERROR' && isOffline || timeout || offline;
};
const _ignoreErrorsFromRaven = error => _ignoreRavenErrorsByStatus(error) || _ignoreRavenErrorsByType(error);
const logError = ({
  error,
  tags,
  extra,
  correlationId = '',
  fingerprint
}) => {
  if (error instanceof ApiError && _ignoreErrorsFromRaven(error.error)) {
    return;
  }
  const errorExtraFields = copyObject(error, {
    excludeKeys: ['message', 'name', 'stack']
  });
  Raven.captureException(error, {
    tags: correlationId ? Object.assign({
      correlationId
    }, tags) : tags,
    extra: Object.assign({}, extra, {
      error: errorExtraFields
    }),
    fingerprint
  });
};
export { logError };