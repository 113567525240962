import { Map as ImmutableMap } from 'immutable';
import { AI_FORECAST, APPOINTMENT, ATTRIBUTION_TOUCH_POINTS, CALL, CARTS, COMMERCE_PAYMENTS, COMPANIES, CONTACTS, CONTACT_CREATE_ATTRIBUTION, CONVERSATIONS, COURSE, DEALS, DEAL_CREATE_ATTRIBUTION, EMAIL_OBJECT, ENGAGEMENTS, FEEDBACK_SUBMISSIONS, FORECAST, GOAL_TARGET, LEADS, LINE_ITEMS, LISTING, MEETING_EVENT, ORDERS, PARTNER_ACCOUNT, PARTNER_CLIENT, PARTNER_SERVICE, PRODUCTS, SEQUENCE_ENROLLMENTS, SEQUENCE_STEP_ENROLLMENTS, SERVICES, TASK, TICKETS, USER } from './dataTypes/inboundDb';
export const HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE = ImmutableMap({
  '0-1': CONTACTS,
  '0-2': COMPANIES,
  '0-3': DEALS,
  '0-4': ENGAGEMENTS,
  '0-5': TICKETS,
  '0-7': PRODUCTS,
  '0-8': LINE_ITEMS,
  '0-11': CONVERSATIONS,
  '0-19': FEEDBACK_SUBMISSIONS,
  '0-20': ATTRIBUTION_TOUCH_POINTS,
  '0-27': TASK,
  '0-47': MEETING_EVENT,
  '0-48': CALL,
  '0-49': EMAIL_OBJECT,
  '0-52': CONTACT_CREATE_ATTRIBUTION,
  '0-60': FORECAST,
  '0-63': DEAL_CREATE_ATTRIBUTION,
  '0-68': SEQUENCE_ENROLLMENTS,
  '0-74': GOAL_TARGET,
  '0-79': SEQUENCE_STEP_ENROLLMENTS,
  '0-101': COMMERCE_PAYMENTS,
  '0-115': USER,
  '0-123': ORDERS,
  '0-125': PARTNER_ACCOUNT,
  '0-136': LEADS,
  '0-142': CARTS,
  '0-145': PARTNER_CLIENT,
  '0-149': AI_FORECAST,
  '0-162': SERVICES,
  '0-164': PARTNER_SERVICE,
  '0-410': COURSE,
  '0-420': LISTING,
  '0-421': APPOINTMENT
});
export const DATA_TYPE_TO_HUBSPOT_OBJECT_COORDINATES = HUBSPOT_OBJECT_COORDINATES_TO_DATA_TYPE.flip();