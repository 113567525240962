import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import * as ReferenceTypes from 'customer-data-objects/externalOptions/ExternalOptionsReferenceTypes';
/**
 * Given a property (or property definition) and an object type ID, return the reference type of that property.
 * @param propertyOrPropertyDefinition  CRM property definition object or property definition object from backend
 * @param objectTypeId                  Object Type ID in the form `metaTypeId-typeId` (i.e. 0-1)
 * @returns                             `ReferenceType` constant for creating a resolver from this library, or
 * `null` if the given property does not map to any reference type
 */
export const getReferenceTypeFromProperty = (propertyOrPropertyDefinition, objectTypeId) => {
  if (!propertyOrPropertyDefinition) {
    return null;
  }
  const property = 'property' in propertyOrPropertyDefinition ? propertyOrPropertyDefinition.property : propertyOrPropertyDefinition;
  if (property.externalOptionsReferenceType) {
    // The properties BE validates that all externalOptionsReferenceType values are valid,
    // so if we see a ref type on a property, we can safely cast and send it along!
    // This validation is called on both create and update: https://git.hubteam.com/HubSpotProtected/InboundDbProperties/blob/25929d5929b95c8edf03df4a5613c1ed3b33d82e/InboundDbPropertiesData/src/main/java/com/hubspot/inbounddb/properties/data/validation/options/ExternalOptionsReferenceTypeValidator.java#L50-L52
    return property.externalOptionsReferenceType;
  }

  // HACK: hs_persona is an explicit omission from the cleanup project, as the property
  // has options that are synced with the reference type in portals. It'll require additional cleanup work.
  // See https://git.hubteam.com/HubSpotProtected/InboundDbProperties/issues/3504#issuecomment-9281184
  if (property.hubspotDefined && objectTypeId === CONTACT_TYPE_ID && property.name === 'hs_persona') {
    return ReferenceTypes.PERSONA;
  }
  return null;
};