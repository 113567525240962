import { useQuery } from 'data-fetching-client';
import { GET_PROCESSING_PAYMENT_METHOD_TYPES } from '../api/queries/upcomingPaymentMethodTypes';
import { useThrowIfNoDataFetchingClient } from './useThrowIfNoDataFetchingClient';
export const useGetProcessingPaymentMethodTypes = ({
  skip
} = {}) => {
  var _data$processingPayme, _data$processingPayme2;
  useThrowIfNoDataFetchingClient();
  const {
    loading,
    data,
    error,
    refetch
  } = useQuery(GET_PROCESSING_PAYMENT_METHOD_TYPES, {
    refetchWritePolicy: 'overwrite',
    skip
  });
  const paymentMethodTypes = (_data$processingPayme = data === null || data === void 0 || (_data$processingPayme2 = data.processingPaymentMethodTypes) === null || _data$processingPayme2 === void 0 ? void 0 : _data$processingPayme2.paymentMethodTypes) !== null && _data$processingPayme !== void 0 ? _data$processingPayme : [];
  if (paymentMethodTypes.includes('ACH')) {
    return {
      loading,
      isACHPaymentsProcessing: true,
      error,
      refetch
    };
  }
  return {
    loading,
    isACHPaymentsProcessing: false,
    error,
    refetch
  };
};