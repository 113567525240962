'use es6';

import { Map as ImmutableMap } from 'immutable';
import { convertDataTypeToObjectTypeId } from '../crmObjects/tsUtils';
import { getCrmObjectPluralName, getCrmObjectProperties, isSupportedCrmObject } from '../crmObjects/utils';
import { MissingPropertiesException } from '../exceptions';
import { Promise } from '../lib/promise';
import { isExternalDataType } from '../report/externalReportGetters';
import { isSupportedUnifiedEventQLDataType } from '../unifiedEventQL/utils';
import { bypassPropertyGetterList } from './constants/bypassPropertyGetter';
import propertyGetters from './data-type';
import { customObjectCountProperty } from './partial/count-property';
import getTotalProperty from './partial/total-property';
import { fetchPropertiesFromRaasApi, isSupportedByReportingApi, isUngatedToSkipPropertyValidation } from './reportingApi/private/utils';
import { getAndValidateSingleObjectProperties, isPropertyProvidedByRaasMetadataStore } from './reportingApi/validateAndFetch';
const mergeMemoize = fn => {
  let cache = ImmutableMap();
  let pendingCache = ImmutableMap();
  const memoized = (key, ...args) => {
    if (cache.has(key) && !args.length) {
      return Promise.resolve(cache);
    }
    if (pendingCache.has(key) && !args.length) {
      return pendingCache.get(key);
    }
    const pendingRequest = fn(key, ...args).then(result => {
      cache = cache.set(key, result);
      return cache;
    });
    pendingCache = pendingCache.set(key, pendingRequest);
    return pendingRequest;
  };
  memoized.reset = () => {
    cache = ImmutableMap();
    pendingCache = ImmutableMap();
  };
  return memoized;
};
const handleShowCurrencySymbol = properties => {
  return properties.map(propertyInfo => {
    return propertyInfo.get('showCurrencySymbol', false) ? propertyInfo.set('type', 'currency') : propertyInfo;
  });
};
const handleDuration = properties => {
  return properties.map(propertyInfo => {
    return propertyInfo.get('numberDisplayHint', false) === 'duration' ? propertyInfo.set('type', 'duration') : propertyInfo;
  });
};
const handleIdProperties = properties => {
  return properties.map(propertyInfo => {
    return propertyInfo.get('type') === 'number' && propertyInfo.get('externalOptions', false) && propertyInfo.get('referencedObjectType') !== null ? propertyInfo.set('type', 'id') : propertyInfo;
  });
};
const getPropertiesViaFE = dataType => {
  if (isSupportedCrmObject(dataType)) {
    return Promise.all([getCrmObjectProperties(dataType), getCrmObjectPluralName(dataType)]).then(([propertyList, pluralName]) => {
      return propertyList.reduce((obj, value) => obj.merge(ImmutableMap({
        [value.get('name')]: value
      })), ImmutableMap({})).merge(getTotalProperty()).merge(customObjectCountProperty(pluralName));
    }).then(handleShowCurrencySymbol).then(handleDuration).then(handleIdProperties);
  }
  return propertyGetters.get(dataType).getProperties().then(properties => properties.merge(getTotalProperty())).then(handleShowCurrencySymbol).then(handleDuration).then(handleIdProperties);
};
const getProperties = (dataType, skipValidationUseBeProps) => {
  const objectTypeId = convertDataTypeToObjectTypeId(dataType);
  return Promise.all([isPropertyProvidedByRaasMetadataStore(objectTypeId), isUngatedToSkipPropertyValidation()]).then(([alreadyValidatedPropertyUseBeProps, ungatedToSkipPropertyValidation]) => {
    if (alreadyValidatedPropertyUseBeProps || skipValidationUseBeProps || isSupportedUnifiedEventQLDataType(dataType)) {
      return fetchPropertiesFromRaasApi(objectTypeId);
    }
    const supportedCrmObject = isSupportedCrmObject(dataType);
    if (supportedCrmObject || propertyGetters.has(dataType)) {
      if (isSupportedByReportingApi(dataType) || supportedCrmObject) {
        // All CRM objects should be validated, only UA reports should use the FE only fetch
        if (ungatedToSkipPropertyValidation) {
          return fetchPropertiesFromRaasApi(objectTypeId);
        }
        return getAndValidateSingleObjectProperties(dataType, getPropertiesViaFE(dataType));
      }
      // Only UA reports should use the FE only fetch
      return getPropertiesViaFE(dataType);
    } else if (bypassPropertyGetterList.includes(dataType)) {
      return Promise.resolve(ImmutableMap());
    } else if (isExternalDataType(dataType)) {
      return fetchPropertiesFromRaasApi(dataType);
    } else {
      return Promise.reject(new MissingPropertiesException(dataType));
    }
  });
};
const cachedProperties = mergeMemoize(getProperties);
export default cachedProperties;
export const __TESTABLE__ = {
  getProperties,
  clearCache: () => {
    cachedProperties.reset();
  }
};