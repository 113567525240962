import { DATA_WELL, DONUT, PIE } from 'reporting-data/constants/chartTypes';
import { AGGREGATION, TIME_SERIES } from 'reporting-data/constants/configTypes';
import { getConfigType, getDimensions } from 'reporting-data/report/configReportGetters';
import { getReportChartType } from 'reporting-data/report/reportGetters';
import { isConfigReport } from 'reporting-data/tsTypes/reportTypes';
import getMeasureSizeRanges from '../../lib/report-utils/getMeasureSizeRanges';
export const getIsReportValidForBreakdownEditorDropdown = ({
  report
}) => {
  return isConfigReport(report) && [AGGREGATION, TIME_SERIES].includes(getConfigType(report)) && getMeasureSizeRanges(report).maxDimensions >= 2;
};
export const getIsDropdownDisabled = report => {
  return !getDimensions(report).first() || [DONUT, DATA_WELL, PIE].includes(getReportChartType(report) || '');
};
export const getIsClearButtonDisabled = report => getDimensions(report).count() < 2;