import { BILLING_ROLES } from '../constants/roles.constants';
import { SUSPENDED_HUB_SCOPE } from '../constants/scopes.constants';
import { PARCEL_ROLES } from '../constants/parcels.constants';
const checkScopesArePresent = (scopesToCheck, userInfo) => {
  if (!userInfo) {
    return false;
  }
  const lookupScopes = Array.isArray(scopesToCheck) ? scopesToCheck : [scopesToCheck];
  return lookupScopes.length > 0 ? lookupScopes.every(s => userInfo.user.scopes.includes(s)) : false;
};
export const checkGatesArePresent = (gatesToCheck, userInfo) => {
  if (!userInfo) {
    return false;
  }
  const lookupGates = Array.isArray(gatesToCheck) ? gatesToCheck : [gatesToCheck];
  return lookupGates.length > 0 ? lookupGates.every(g => userInfo.gates.includes(g)) : false;
};
export const checkHubSuspended = userInfo => {
  return checkScopesArePresent(SUSPENDED_HUB_SCOPE, userInfo);
};
export const checkUserHasRole = (role, userInfo) => {
  return checkScopesArePresent(BILLING_ROLES[role].scopes, userInfo);
};
export const checkParcelIfOfType = (parcel, userInfo) => {
  return checkScopesArePresent(PARCEL_ROLES[parcel].scopes, userInfo);
};