module.exports = {
  "resurrectionBannerInteraction": {
    "name": "resurrectionbanner",
    "class": "interaction",
    "properties": {
      "action": {
        "type": "string"
      },
      "featureLimitName": {
        "type": "string"
      },
      "experimentCohort": {
        "type": [
          "control",
          "variation"
        ],
        "isOptional": true
      }
    },
    "namespace": "growth-feature-usage-components",
    "version": "1"
  }
};