import { USERS_READ_SCOPE, SEATS_ACCESS_SCOPE, MARKETABLE_CONTACTS_ACCESS_SCOPE, DEDICATED_IP_ADD_ON_SCOPE } from './scopes.constants';
export const MARKETABLE_CONTACTS_PARCEL = 'MARKETABLE_CONTACTS_PARCEL';
export const SEATS_PARCEL = 'SEATS_PARCEL';
export const USERS_READ_PARCEL = 'USERS_READ_PARCEL';
export const DEDICATED_IP_PARCEL = 'DEDICATED_IP_PARCEL';
export const PARCEL_ROLES = {
  [MARKETABLE_CONTACTS_PARCEL]: {
    scopes: [MARKETABLE_CONTACTS_ACCESS_SCOPE]
  },
  [SEATS_PARCEL]: {
    scopes: [SEATS_ACCESS_SCOPE]
  },
  [USERS_READ_PARCEL]: {
    scopes: [USERS_READ_SCOPE]
  },
  [DEDICATED_IP_PARCEL]: {
    scopes: [DEDICATED_IP_ADD_ON_SCOPE]
  }
};