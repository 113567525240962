import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
import { getReportCustomized } from 'reporting-data/report/reportGetters';
import { getBreakdownPropertiesForReport, getEditorSchemas, hasFirstDimensionControlSupport } from './salesAnalyticsHelpers';
// @ts-expect-error Untyped dependency
import { deriveGroupingFromReport } from '../constants/sub-app/contextDefaults';
import { getMetricOptionKeys, getTableOnlyMetricOptionKeys
// @ts-expect-error Untyped dependency
} from '../constants/dataTypeMetricOptions';
// @ts-expect-error Untyped dependency
import { getQuickFilterSchemasForReport } from './editorSchemaHelpers';
// @ts-expect-error Untyped dependency
import { getDimensionPropertiesForSchema } from './editorSchemaHelpers';
import { isConfigReport, isCustomWidgetReport, isJourneyReport } from 'reporting-data/tsTypes/reportTypes';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
// @ts-expect-error Untyped dependency
import { getSubAppKeyFromReport } from './context-report-sync';
// @ts-expect-error Untyped dependency
import SalesAnalyticsEditor from '../components/SalesAnalyticsEditor';
import { List } from 'immutable';

// Helps us ensure we include editors only when expected.

const EDITOR_ALLOW_SETTINGS = {
  [EDITOR_TYPES.TABLE_METRIC]: {
    not: false,
    subApps: [SUB_APPS.sources, SUB_APPS.pages, SUB_APPS.utmParameters, SUB_APPS.deviceTypes, SUB_APPS.geolocation, SUB_APPS.browsers, SUB_APPS.ads, SUB_APPS.leadCapture]
  },
  [EDITOR_TYPES.OVERTIME_TOTALS]: {
    not: false,
    subApps: [SUB_APPS.sources, SUB_APPS.pages, SUB_APPS.utmParameters, SUB_APPS.deviceTypes, SUB_APPS.geolocation, SUB_APPS.browsers, SUB_APPS.ads, SUB_APPS.leadCapture]
  }
};
const checkEditorAllowed = (editorType, subAppKey) => {
  const allowSettings = EDITOR_ALLOW_SETTINGS[editorType];
  if (!allowSettings) {
    return true;
  }
  return allowSettings.not ? !allowSettings.subApps.includes(subAppKey) : allowSettings.subApps.includes(subAppKey);
};
const shouldOverrideSrvEditors = report => {
  return isCustomWidgetReport(report) || isJourneyReport(report) || [SUB_APPS.timeInDealStage, SUB_APPS.ticketTimeInPipeline].includes(getSubAppKeyFromReport(report));
};
const getEditorSchemasForSavedSalesAnalyticsReport = (report, gates) => {
  const subAppKey = getSubAppKeyFromReport(report);
  if (!subAppKey || getReportCustomized(report) || shouldOverrideSrvEditors(report) || !(isConfigReport(report) || isJourneyReport(report))) {
    return [];
  }
  const grouping = deriveGroupingFromReport(report, subAppKey, gates);
  const existingEditors = getEditorSchemas(report);
  const editorSchemas = existingEditors;
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.QUICK_FILTER)) {
    const quickFilterSchemas = getQuickFilterSchemasForReport(report, subAppKey, grouping, gates);
    editorSchemas.push(...quickFilterSchemas);
  }
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.DIMENSION)) {
    const dimensionProperties = getDimensionPropertiesForSchema(report, subAppKey, grouping, gates);
    if (hasFirstDimensionControlSupport(report) && dimensionProperties.length > 1) {
      editorSchemas.push({
        type: EDITOR_TYPES.DIMENSION,
        properties: dimensionProperties
      });
    }
  }
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.BREAKDOWN)) {
    const breakdownDimensionsToProperties = getBreakdownPropertiesForReport(subAppKey);
    const breakdownProperties = Object.values(breakdownDimensionsToProperties);
    if (breakdownProperties.length > 0) {
      editorSchemas.push({
        type: EDITOR_TYPES.BREAKDOWN,
        properties: breakdownProperties
      });
    }
  }
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.METRIC)) {
    const metrics = getMetricOptionKeys(report, subAppKey);
    if (metrics.length > 1) {
      editorSchemas.push({
        type: EDITOR_TYPES.METRIC,
        metrics
      });
    }
  }
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.TABLE_METRIC) && checkEditorAllowed(EDITOR_TYPES.TABLE_METRIC, subAppKey)) {
    const metrics = getMetricOptionKeys(report, subAppKey).concat(getTableOnlyMetricOptionKeys(report, subAppKey));
    if (metrics.length > 1) {
      editorSchemas.push({
        type: EDITOR_TYPES.TABLE_METRIC,
        metrics
      });
    }
  }
  if (!existingEditors.find(schema => schema.type === EDITOR_TYPES.OVERTIME_TOTALS) && checkEditorAllowed(EDITOR_TYPES.OVERTIME_TOTALS, subAppKey)) {
    editorSchemas.push({
      type: EDITOR_TYPES.OVERTIME_TOTALS
    });
  }
  return editorSchemas;
};
export const addEditorsToSavedSalesAnalyticsReport = (report, gates) => {
  const salesAnalyticsSchemas = getEditorSchemasForSavedSalesAnalyticsReport(report, gates);
  return salesAnalyticsSchemas.length > 0 ? report.setIn(['editors'], List(salesAnalyticsSchemas)) : report;
};
export const getSalesAnalyticsEditorOverrides = report => {
  if (SalesAnalyticsEditor.isUsableForReport(report) && shouldOverrideSrvEditors(report)) {
    return [SalesAnalyticsEditor];
  }
  return undefined;
};