import I18n from 'I18n';
export const getFeatureTranslation = featureLimitName => {
  const featureStrings = {
    'pipeline-deals': I18n.text('resurrectionBanner.pipeline-deals'),
    templates: I18n.text('resurrectionBanner.templates'),
    'object-lists-dynamic': I18n.text('resurrectionBanner.object-lists-dynamic'),
    dashboards: I18n.text('resurrectionBanner.dashboards')
  };
  return featureStrings[featureLimitName];
};
export const getSubscriptionTranslation = featureLimitName => {
  const featureStrings = {
    'crm-suite-starter-bundle': I18n.text('resurrectionBanner.crm-suite-starter-bundle'),
    'marketing-hub-starter': I18n.text('resurrectionBanner.marketing-hub-starter'),
    'sales-hub-starter': I18n.text('resurrectionBanner.sales-hub-starter'),
    'service-hub-starter': I18n.text('resurrectionBanner.service-hub-starter'),
    'cms-hub-starter': I18n.text('resurrectionBanner.cms-hub-starter'),
    'operations-hub-starter': I18n.text('resurrectionBanner.operations-hub-starter')
  };
  return featureStrings[featureLimitName];
};