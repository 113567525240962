'use es6';

import { createSelector } from 'reselect';
import compose from 'transmute/compose';
import get from 'transmute/get';
import map from 'transmute/map';
import memoizeLast from 'transmute/memoizeLast';
import { selectResource } from '../request/redux-request';
import { getStatus, toValueWithDefault } from '../request/resource-state';
import { reportSentenceNamespace, reportConfigNamespace, dashboardSentenceNamespace, dashboardConfigNamespace, rootNameSpace } from './permission-action';
import toJS from 'transmute/toJS';
import { PermissionSentence, PermissionConfigPayload, sentenceToPermission, toState } from 'dashboard-lib/public/permission/permission-payload';
import { PermissionLevel } from './permission-level';
const unwrapPermissionSentence = toValueWithDefault(PermissionSentence({
  permissionLevel: PermissionLevel.NONE
}));
const selectPermissionRoot = selectResource(rootNameSpace);
const selectDashboardPermissionSentenceRoot = compose(get(dashboardSentenceNamespace), selectPermissionRoot);
const selectDashboardPermissionConfigRoot = compose(get(dashboardConfigNamespace), selectPermissionRoot);
export const selectDashboardPermissionConfigStatus = createSelector([selectDashboardPermissionConfigRoot], getStatus);
const selectDashboardPermissionConfigValue = createSelector([selectDashboardPermissionConfigRoot], toValueWithDefault(PermissionConfigPayload()));
export const selectDashboardPermissionConfigAsState = createSelector([selectDashboardPermissionConfigValue], toState);
const selectDashboardPermissionConfigValueGranular = createSelector([selectDashboardPermissionConfigRoot], dashboardPermissionConfigRoot => dashboardPermissionConfigRoot);
export const selectDashboardPermissionConfigAsStateGranular = createSelector([selectDashboardPermissionConfigValueGranular], payload => toJS(payload).value);
export const selectDashboardPermissionSentenceStatus = dashboardId => createSelector([selectDashboardPermissionSentenceRoot], compose(getStatus, get(dashboardId)));
const makeDashboardPermissionSentenceSelector = memoizeLast(dashboardId => createSelector([selectDashboardPermissionSentenceRoot], compose(unwrapPermissionSentence, get(dashboardId))));
const selectDashboardPermissionSentenceMap = createSelector([selectDashboardPermissionSentenceRoot], map(unwrapPermissionSentence));

/**
 * If the resource is not available, defaults to {AssetPermission.ZERO}
 * @param dashboardId
 * @returns {AssetPermission}
 */
export const selectDashboardPermission = (dashboardId, fallback) => dashboardId ? createSelector([makeDashboardPermissionSentenceSelector(dashboardId)], sentenceToPermission) : () => fallback;

/**
 * @returns Map of dashboardId to {AssetPermission}
 */
export const selectDashboardPermissionMap = createSelector([selectDashboardPermissionSentenceMap], map(sentenceToPermission));
const selectReportPermissionSentenceRoot = compose(get(reportSentenceNamespace), selectPermissionRoot);
const selectReportPermissionConfigRoot = compose(get(reportConfigNamespace), selectPermissionRoot);
export const selectReportPermissionConfigStatus = createSelector([selectReportPermissionConfigRoot], getStatus);
const selectReportPermissionConfigValue = createSelector([selectReportPermissionConfigRoot], toValueWithDefault(PermissionConfigPayload()));
export const selectReportPermissionConfigAsState = createSelector([selectReportPermissionConfigValue], toState);
export const selectReportPermissionSentenceStatus = reportId => createSelector([selectReportPermissionSentenceRoot], compose(getStatus, get(reportId)));
const makeReportPermissionSentenceSelector = memoizeLast(reportId => createSelector([selectReportPermissionSentenceRoot], compose(unwrapPermissionSentence, get(reportId))));
const selectReportPermissionSentenceMap = createSelector([selectReportPermissionSentenceRoot], map(unwrapPermissionSentence));

/**
 * If the resource is not available, defaults to {AssetPermission.ZERO}
 * @param reportId
 * @returns {AssetPermission}
 */
export const selectReportPermission = (reportId, fallback) => reportId ? createSelector([makeReportPermissionSentenceSelector(reportId)], sentenceToPermission) : () => fallback;

/**
 * @returns Map of reportId to {AssetPermission}
 */
export const selectReportPermissionMap = createSelector([selectReportPermissionSentenceMap], map(sentenceToPermission));