module.exports = {
  "usageInteraction": {
    "name": "usage interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click ctu alert button",
          "click threshold alert cta",
          "click threshold alert pricing cta"
        ]
      }
    },
    "namespace": "account-and-billing-ui"
  },
  "usageEnrichmentInteraction": {
    "name": "usage enrichment interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "click ctu alert button",
          "click threshold alert cta"
        ]
      }
    },
    "namespace": "account-and-billing-ui"
  }
};