import { registerQuery, useQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../clients/hubHttpWithRaven';
import { getCancellableProducts, getCancelledProducts } from '../utils/productsCancellation.util';
import { getDataFetchingClient } from '../clients/dataFetchingClient';
export const GET_SUBSCRIPTIONS = registerQuery({
  fieldName: 'subscriptionsList',
  fetcher: () => httpClientWithRaven.get(`subscription-experience/v1/subscriptions`)
});
const shouldShowAutoRenewalButton = products => {
  const cancellableProducts = getCancellableProducts(products);
  const cancelledProducts = getCancelledProducts(cancellableProducts);
  return cancelledProducts.length > 0;
};
function useSubscriptionsQuery({
  skip
}) {
  return useQuery(GET_SUBSCRIPTIONS, {
    client: getDataFetchingClient(),
    skip
  });
}
export function useShouldShowTurnAutoRenewalButton(props) {
  const isFetchProps = p => p.subscriptionId !== undefined;
  const {
    data,
    loading,
    error,
    refetch
  } = useSubscriptionsQuery({
    skip: !isFetchProps(props)
  });
  if (error) {
    return {
      shouldShowTurnAutoRenewalButton: false,
      loading,
      error,
      refetch
    };
  }
  if (isFetchProps(props)) {
    const subscription = data === null || data === void 0 ? void 0 : data.subscriptionsList.find(item => item.subscriptionId === props.subscriptionId);
    if (!subscription) {
      return {
        shouldShowTurnAutoRenewalButton: false,
        loading,
        error: new Error('Subscription not found'),
        refetch
      };
    }
    return {
      shouldShowTurnAutoRenewalButton: shouldShowAutoRenewalButton(subscription.products),
      loading,
      refetch
    };
  } else {
    return {
      shouldShowTurnAutoRenewalButton: shouldShowAutoRenewalButton(props.products)
    };
  }
}