import http from 'hub-http/clients/apiClient';
import { registerQuery } from 'data-fetching-client';
export const getAllTeams = () => http.get('app-users/v1/teams');
export const getTeamHierarchies = () => http.get('app-users/v1/teams/hierarchy');
export const FETCH_ALL_TEAMS = registerQuery({
  fieldName: 'hubTeams',
  args: [],
  fetcher() {
    return getAllTeams();
  }
});
export const FETCH_ALL_TEAM_HIERARCHIES = registerQuery({
  fieldName: 'hubTeamHierarchies',
  args: [],
  fetcher() {
    return getTeamHierarchies();
  }
});