import { copyObject } from './copyObject';
export function normalizeUrl(url) {
  let pathname = url;
  try {
    const urlObject = new URL(url);
    pathname = urlObject.pathname;
  } catch (e) {
    // do nothing
  }
  const regex = /(\/)\d+(\/|$)/g;
  while (regex.test(pathname)) {
    pathname = pathname.replace(regex, '$1XXXXXX$2');
  }
  return pathname;
}
export function getMessage({
  url,
  method,
  error
}) {
  if (typeof url === 'string' && typeof method === 'string') {
    let errorDataMessage = '';
    if (typeof error === 'object' && error) {
      const errorStatus = error.status;
      const errorCode = error.errorCode;
      const errorStatusMessage = typeof errorStatus === 'string' || typeof errorStatus === 'number' ? `errorStatus ${errorStatus}` : undefined;
      const errorCodeMessage = typeof errorCode === 'string' || typeof errorCode === 'number' ? `errorCode ${errorCode}` : undefined;
      errorDataMessage = [errorStatusMessage, errorCodeMessage].filter(Boolean).join(' and ');
    }
    return `${method} ${normalizeUrl(url)} failed${errorDataMessage ? ` with ${errorDataMessage}` : ''}`;
  }
  if (typeof error === 'object' && error && typeof error.message === 'string') {
    return error.message;
  }
  return 'Request failed';
}
export class ApiError extends Error {
  constructor(message, {
    error
  }) {
    super(message);

    // error.options has the hub-http options
    // They are not required and unnecessarily bloat error
    // object causing very large sentry requests
    const ignoreProperties = ['options', 'xhr', 'responseText', 'stack', 'cause'];
    this.error = copyObject(error, {
      excludeKeys: ignoreProperties
    });
  }
  static fromRestError({
    error,
    request
  }) {
    const message = getMessage({
      url: request ? request.url : undefined,
      method: request ? request.method : undefined,
      error
    });
    return new ApiError(message, {
      error
    });
  }
}