import { registerQuery, useQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../../../clients/hubHttpWithRaven';
export const GET_ENRICHMENT_TIER_UPGRADE_QUERY = registerQuery({
  fieldName: 'enrichmentTierUpgradeData',
  args: [],
  fetcher() {
    return httpClientWithRaven.get('data-enrichment-app/notifications/v1/notifications/banner/tier-upgrade');
  }
});
export const useEnrichmentTierUpgradeData = ({
  skip
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_ENRICHMENT_TIER_UPGRADE_QUERY, {
    skip
  });
  return {
    data: data === null || data === void 0 ? void 0 : data.enrichmentTierUpgradeData,
    loading,
    error
  };
};