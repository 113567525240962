import { subscriptionToParcelMap } from '../subscriptionToParcelMap';
export const getParcelsFromSubscriptions = subscriptions => {
  const parcels = subscriptions.filter(subscription => Object.hasOwn(subscriptionToParcelMap, subscription)).flatMap(subscription => subscriptionToParcelMap[subscription]);
  return [...new Set(parcels)];
};
export const getLimitById = (limits, id) => limits.find(limit => limit.limitId === id);
export const sortByFeatureLimitOrderAsc = (featureLimits, priorityList) => {
  return featureLimits.sort((a, b) => {
    const indexA = priorityList.indexOf(a.limitName);
    const indexB = priorityList.indexOf(b.limitName);
    return indexA - indexB;
  });
};
const getLimitOrZero = (parcelLimitVersions, limitId) => {
  var _getLimitById$limit, _getLimitById;
  return (_getLimitById$limit = (_getLimitById = getLimitById(parcelLimitVersions, limitId)) === null || _getLimitById === void 0 ? void 0 : _getLimitById.limit) !== null && _getLimitById$limit !== void 0 ? _getLimitById$limit : 0;
};
const hasSameOrHigherLimit = (limitId, limit, retainedParcelLimitVersions) => {
  return retainedParcelLimitVersions.some(limitVersion => {
    return limitVersion.limitId === limitId && limitVersion.limit >= limit;
  });
};
export const getParcelNames = (currentParcelLimitVersions, limitId) => {
  return currentParcelLimitVersions.filter(currentParcelLimitVersion => currentParcelLimitVersion.limitId === limitId).map(currentParcelLimitVersion => currentParcelLimitVersion.parcelName);
};
export function calculatePortalFeatureLoss(featureUsage, currentParcelLimitVersions, downgradedParcelLimitVersions, retainedParcelLimitVersions, featureLimitNamesByPriority, includeZeroAndNoLossUsage = false, productApiNames) {
  const portalFeatureLoss = featureUsage.filter(({
    limitId,
    usage,
    limitName,
    limit
  }) => {
    const limitInPriorityList = featureLimitNamesByPriority.includes(limitName);
    const limitExistsInCurrentParcelLimitVersions = currentParcelLimitVersions.some(version => version.limitId === limitId);
    const lowerLimit = getLimitOrZero(downgradedParcelLimitVersions, limitId);
    const sameOrHigherLimitRetained = hasSameOrHigherLimit(limitId, limit !== null && limit !== void 0 ? limit : 0, retainedParcelLimitVersions);
    const defaultUsageCondition = usage > 0 && usage > lowerLimit;
    const usageCondition = includeZeroAndNoLossUsage || defaultUsageCondition;
    return !sameOrHigherLimitRetained && limitInPriorityList && limitExistsInCurrentParcelLimitVersions && usageCondition;
  }).map(({
    limitId,
    limitName,
    usage,
    limit
  }) => {
    const lowerLimit = getLimitOrZero(downgradedParcelLimitVersions, limitId);
    const parcelNames = getParcelNames(currentParcelLimitVersions, limitId);
    return {
      limitId,
      limitName,
      usage,
      lowerLimit,
      currentLimit: limit,
      parcelNames,
      productApiNames
    };
  });
  const sortedPortalFeatureLoss = sortByFeatureLimitOrderAsc(portalFeatureLoss, featureLimitNamesByPriority);
  return sortedPortalFeatureLoss;
}