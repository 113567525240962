'use es6';

import { CRM_OBJECT_META_TYPE } from 'reporting-data/constants/crmObjectMetaTypes';
import { fromObjectSegFilterStrict } from 'customer-data-filters/converters/objectSeg/fromObjectSegFilter';
import { getPropertyResolver } from 'reference-resolvers-lite/utils/getPropertyResolver';
import { DataSourceTypes } from '../../relational/schema/data-source';
import { SNOWFLAKE_FILTER_FAMILY, isSupportedExternalOption, canUseReferenceResolverLiteForFilterInput } from '../../relational/modify/filters/xo-filter-utils';
import { getIsUngated } from '../../shared/lib/utils';
export const getDataSourceType = objectTypeId => objectTypeId.split('-')[0] === CRM_OBJECT_META_TYPE.HUBSPOT ? DataSourceTypes.HUBSPOT_OBJECT : DataSourceTypes.HUBSPOT_EVENT;
export const getOperatorFromFilterAndField = (filter, field, userGates) => fromObjectSegFilterStrict(filter, () => field, SNOWFLAKE_FILTER_FAMILY, gate => getIsUngated(gate, userGates), true);
export const getFamilyValueResolverGetter = (objectTypeId, snowflakeProperty, field, resolvers, getReferencedObjectType) => () => {
  const useReferenceResolverLite = canUseReferenceResolverLiteForFilterInput(snowflakeProperty) && isSupportedExternalOption(objectTypeId, snowflakeProperty);
  const referenceResolverLite = useReferenceResolverLite ? getPropertyResolver({
    property: field,
    objectTypeId
  }) : null;
  return referenceResolverLite || resolvers[getReferencedObjectType()] || null;
};