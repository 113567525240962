import { useEffect, useState } from 'react';
import { checkWithinSevenDays } from '../utils/renewalDate';
import { localStorage } from '../utils/localStorage';
export const useHasLocalStorageDismissal = subscriptionRenewalDate => {
  const [dismissInLocalStorage, setDismissInLocalStorage] = useState(localStorage.getItem('res0010dismissed'));
  useEffect(() => {
    if (checkWithinSevenDays(new Date(subscriptionRenewalDate)) && dismissInLocalStorage !== '7days') {
      localStorage.removeItem('res0010dismissed');
      setDismissInLocalStorage(null);
    }
  }, [dismissInLocalStorage, subscriptionRenewalDate]);
  return {
    dismissInLocalStorage,
    setDismissInLocalStorage
  };
};