import http from 'hub-http/clients/apiClient';
import { registerQuery } from 'data-fetching-client';
// API
export const getSubscriptions = () => {
  return http.get('subscription-experience/v1/subscriptions');
};

// Register Query
export const GET_SUBSCRIPTIONS_QUERY = registerQuery({
  fieldName: 'getSubscriptions',
  fetcher: () => getSubscriptions()
});