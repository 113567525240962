import { HubsSettingClient } from 'frontend-preferences-client';
import { useEffect, useState } from 'react';
export const hubSettingsClient = HubsSettingClient.forCaller('transactions-experience-ui');
export const useFetchHubSettings = (key, defaultValue) => {
  const [isHidden, setHiddenStatus] = useState(true);
  useEffect(() => {
    hubSettingsClient.fetch(key, defaultValue).then(result => {
      if (result !== 'true') {
        setHiddenStatus(false);
      }
    }).catch(() => {
      /** no-op */
    });
  }, [defaultValue, key]);
  return {
    setHiddenStatus,
    isHidden
  };
};