export const isProductCanceled = product => product.cancellationStatus.pendingCancellationItemId !== null;
const extractCanceledProductInfo = (subscription, product) => ({
  subscriptionRenewalDate: subscription.renewalDate,
  productApiName: product.productApiName,
  subscriptionId: subscription.subscriptionId
});

// getCanceledProductsFromSubscriptions returns an object like:
//   [{
//         "subscriptionRenewalDate": "2025-11-27",
//         "productApiName": "marketing-hub-starter"
//          subscriptionId: 152000156,

//     },
//     {
//         "subscriptionRenewalDate": "2025-11-27",
//         "productApiName": "operations-hub-starter",
//          subscriptionId: 152000156,
//   }]
// or [] if there are no canceled products
// Please refer to unit test for more information
export const getCanceledProductsFromSubscriptions = subscriptions => {
  const canceledProducts = [];
  subscriptions.forEach(subscription => subscription.products.forEach(product => {
    if (isProductCanceled(product)) {
      canceledProducts.push(extractCanceledProductInfo(subscription, product));
    }
  }));
  return canceledProducts;
};
export const getAllSubscriptionProducts = subscriptions => {
  const subscriptionProducts = [];
  subscriptions.forEach(subscription => subscription.products.forEach(product => {
    subscriptionProducts.push(extractCanceledProductInfo(subscription, product));
  }));
  return subscriptionProducts;
};