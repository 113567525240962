'use es6';

import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
const CATEGORIES = {
  ACTIVITIES_AND_LEADS: 'activitiesAndLeads',
  DEALS: 'deals',
  FORECAST_AND_REVENUE: 'forecastAndRevenue'
};
export const CATEGORY_SUB_APPS = {
  [CATEGORIES.ACTIVITIES_AND_LEADS]: [SUB_APPS.activities, SUB_APPS.activityStream, SUB_APPS.callOutcomes, SUB_APPS.conversations, SUB_APPS.leadFunnel, SUB_APPS.leadResponseTime, SUB_APPS.meetingOutcomes, SUB_APPS.prospectEngagement, SUB_APPS.taskOutcomes],
  [CATEGORIES.DEALS]: [SUB_APPS.averageSalesPrice, SUB_APPS.changeHistoryDealList, SUB_APPS.dealJourney, SUB_APPS.dealPushAnalysis, SUB_APPS.dealsCreatedByRep, SUB_APPS.dealsWon, SUB_APPS.dealVelocity, SUB_APPS.lossReasons, SUB_APPS.salesVelocity, SUB_APPS.timeInDealStage, SUB_APPS.waterfall],
  [CATEGORIES.FORECAST_AND_REVENUE]: [SUB_APPS.lineItemRevenue, SUB_APPS.manualForecast, SUB_APPS.manualForecastTotal, SUB_APPS.pipelineSnapshots, SUB_APPS.quotaAttainment, SUB_APPS.salesBySource, SUB_APPS.weightedPipelineForecast]
};