import { registerQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../clients/hubHttpWithRaven';
export const GET_MIGRATIONS = registerQuery({
  fetcher() {
    return httpClientWithRaven.get('service-migrations/v1/customers/migrations', {
      ravenOptions: {
        message: 'Error fetching migrations'
      }
    });
  },
  args: [],
  fieldName: 'currentMigrations'
});