import { userInfoSync } from 'hub-http/userInfo';
export const getHasBillingWriteScope = () => {
  const {
    user: {
      scopes
    }
  } = userInfoSync();
  return scopes.includes('billing-write');
};
export const getUserInfo = () => {
  const userInfo = userInfoSync();
  return userInfo;
};