import { useQuery } from 'data-fetching-client';
import { GET_FINTECH_ZUORA_OUTAGE } from '../api/queries/fintechOutages';
import { OUTAGE_REASONS } from '../constants/outageReasons';
import { getIn } from '../utils/getIn';
import { logError } from '../utils/logError';
import { useThrowIfNoDataFetchingClient } from './useThrowIfNoDataFetchingClient';
const getIsDown = response => {
  const isDown = getIn(['down'], response, false);
  if (typeof isDown !== 'boolean') {
    const message = `Expected isDown to be a boolean, received ${typeof isDown}`;
    logError({
      error: new Error(message)
    });
    return false;
  }
  return isDown;
};
const getOutageReason = response => {
  const isDown = getIsDown(response);
  if (!isDown) {
    return null;
  }
  const reason = getIn(['reason'], response, null);
  if (!reason) {
    return OUTAGE_REASONS.UNPLANNED_OUTAGE;
  }
  if (![OUTAGE_REASONS.PLANNED_OUTAGE, OUTAGE_REASONS.UNPLANNED_OUTAGE].includes(reason)) {
    const message = `Expected ${OUTAGE_REASONS.PLANNED_OUTAGE} or ${OUTAGE_REASONS.UNPLANNED_OUTAGE} as outage reason, got ${reason}`;
    logError({
      error: new Error(message)
    });
    return OUTAGE_REASONS.UNPLANNED_OUTAGE;
  }
  return reason;
};
const getOutageTimestamp = (response, key) => {
  const isDown = getIsDown(response);
  if (!isDown) {
    return null;
  }
  const timestamp = getIn([key], response, null);
  if (timestamp === null) {
    return null;
  }
  if (typeof timestamp !== 'number' || timestamp <= 0) {
    const message = `Expected outage ${key} to be a valid timestamp`;
    logError({
      error: new Error(message)
    });
    return null;
  }
  return timestamp;
};
const mapResponse = response => {
  return {
    isDown: getIsDown(response),
    outageReason: getOutageReason(response),
    outageStartTime: getOutageTimestamp(response, 'startTime'),
    outageEndTime: getOutageTimestamp(response, 'endTime')
  };
};
export const useGetFintechOutageStatus = portalId => {
  useThrowIfNoDataFetchingClient();
  const result = useQuery(GET_FINTECH_ZUORA_OUTAGE, {
    variables: {
      hubId: portalId
    },
    pollInterval: 60 * 1000
  });
  const {
    data,
    loading,
    error
  } = result;
  const fintechOutageInfo = getIn(['fintechZuoraOutageStatus'], data);
  return {
    availabilityStatus: {
      zuora: mapResponse(fintechOutageInfo)
    },
    loading,
    error
  };
};