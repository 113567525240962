import { localStorage } from '../utils/localStorage';
export function checkWithinSixtyDays(renewalDate) {
  const debugDays = localStorage.getItem('res0010renewaldate');
  const days = Number(debugDays) || 60;
  const today = new Date();
  const diffInTime = renewalDate.getTime() - today.getTime();
  const diffInDays = diffInTime / (1000 * 3600 * 24);
  const withinSixtyDays = diffInDays <= days;
  return withinSixtyDays;
}
export function checkWithinSevenDays(renewalDate) {
  const debugDays = localStorage.getItem('res0010renewaldate');
  const days = Number(debugDays) || 7;
  const today = new Date();
  const diffInTime = renewalDate.getTime() - today.getTime();
  const diffInDays = Math.abs(diffInTime / (1000 * 3600 * 24));
  const withinSevenDays = diffInDays <= days;
  return withinSevenDays;
}
export const getUpcomingRenewalSubscriptions = canceledProducts => canceledProducts.filter(product => checkWithinSixtyDays(new Date(product.subscriptionRenewalDate)) || checkWithinSevenDays(new Date(product.subscriptionRenewalDate)));