import http from 'hub-http/clients/apiClient';
import compose from 'transmute/compose';
import fromJS from 'transmute/fromJS';
import get from 'transmute/get';
import indexBy from 'transmute/indexBy';
export const fetchDashboardSentenceSingle = ({
  dashboardId,
  userId
}) => {
  return http.get(`/dashboard/v2/dashboard/${dashboardId}/permission/${userId}`);
};
export const fetchDashboardSentenceMulti = ({
  userId
}) => {
  return http.get(`/dashboard/v2/dashboard/permission/${userId}`).then(response => {
    return compose(indexBy(get('dashboardId')), fromJS, get('permissionSentences'))(response);
  });
};
export const fetchDashboardConfigSingle = ({
  dashboardId
}) => {
  return http.get(`/dashboard/v2/dashboard/${dashboardId}/permission`);
};
export const putDashboardConfigSingle = ({
  dashboardId,
  data
}) => {
  return http.put(`/dashboard/v2/dashboard/${dashboardId}/permission`, {
    data
  });
};
export const fetchDashboardConfigSingleGranular = ({
  dashboardId
}) => {
  return http.get(`/dashboard/v2/dashboard/${dashboardId}/permissions`);
};
export const putDashboardConfigSingleGranular = ({
  dashboardId,
  data
}) => {
  return http.put(`/dashboard/v2/dashboard/${dashboardId}/permissions`, {
    data
  });
};
export const fetchReportSentenceSingle = ({
  reportId,
  userId
}) => {
  return http.get(`/dashboard/v2/reports/${reportId}/permission/${userId}`);
};
export const fetchReportSentenceMulti = ({
  userId
}) => {
  return http.get(`/dashboard/v2/reports/permission/${userId}`).then(response => {
    return compose(indexBy(get('reportId')), fromJS, get('permissionSentences'))(response);
  });
};
export const fetchReportConfigSingle = ({
  reportId
}) => {
  return http.get(`/dashboard/v2/reports/${reportId}/permission`);
};
export const putReportConfigSingle = ({
  reportId,
  data
}) => {
  return http.put(`/dashboard/v2/reports/${reportId}/permission`, {
    data
  });
};