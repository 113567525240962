import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["data"];
import { useMemo } from 'react';
import { DataFetchingClient, useQuery } from 'data-fetching-client';
import { GET_SUBSCRIPTIONS_QUERY } from '../api/subscriptions';
export const useSubscriptions = ({
  skip
} = {}) => {
  var _data$getSubscription;
  const client = useMemo(() => new DataFetchingClient(), []);
  const _useQuery = useQuery(GET_SUBSCRIPTIONS_QUERY, {
      client,
      skip
    }),
    {
      data
    } = _useQuery,
    rest = _objectWithoutPropertiesLoose(_useQuery, _excluded);
  return Object.assign({}, rest, {
    data: (_data$getSubscription = data === null || data === void 0 ? void 0 : data.getSubscriptions) !== null && _data$getSubscription !== void 0 ? _data$getSubscription : []
  });
};