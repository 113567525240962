export const BILLING_WRITE_SCOPE = 'billing-write';
export const BILLING_READ_SCOPE = 'billing-read';
export const BILLING_READ_RESTRICTED_SCOPE = 'billing-read-restricted';
export const MARKETABLE_CONTACTS_WRITE_SCOPE = 'marketable-contacts-write';
export const SUSPENDED_HUB_SCOPE = 'suspended';
export const PARTNER_DOCUMENT_READ_SCOPE = 'partner-document-read';
export const PARTNER_DOCUMENT_WRITE_SCOPE = 'partner-document-write';
export const HUB_CONTACT_ROLES_WRITE_SCOPE = 'hub-contact-roles-write';
export const MARKETABLE_CONTACTS_ACCESS_SCOPE = 'marketable-contacts-access';
export const SEATS_ACCESS_SCOPE = 'seats-access';
export const USERS_READ_SCOPE = 'users-read';
export const DEDICATED_IP_ADD_ON_SCOPE = 'dedicated-ip-marketing';
export const CONTENT_MIGRATIONS_STATUS_VIEW_SCOPE = 'content-migrations-status-view';