import { useSubscriptions } from 'growth-feature-usage-data/hooks/useSubscriptions';
import { getAllSubscriptionProducts, getCanceledProductsFromSubscriptions } from 'growth-feature-usage-components/utils/productCancelation';
import { getUpcomingRenewalSubscriptions } from 'growth-feature-usage-components/utils/renewalDate';
import { getHasBillingWriteScope } from '../utils/scopes';
export const useHasUpcomingCanceledSubscription = () => {
  const hasBillingScope = getHasBillingWriteScope();
  const {
    data,
    loading
  } = useSubscriptions({
    skip: !hasBillingScope
  });
  if (loading) {
    return {
      loading: true
    };
  }
  if (!hasBillingScope) {
    return {
      loading: false
    };
  }

  // If there are no canceled products, the user is not eligible for the resurrection banner
  const canceledProducts = getCanceledProductsFromSubscriptions(data);
  if (canceledProducts.length === 0) {
    return {
      loading: false
    };
  }

  // Check if the renewal date is within 7 days or 60 days
  // If there are no upcoming renewal subscriptions, the user is not eligible for the resurrection banner
  const upcomingRenewalSubscriptions = getUpcomingRenewalSubscriptions(canceledProducts);
  if (upcomingRenewalSubscriptions.length === 0) {
    return {
      loading: false
    };
  }
  return {
    loading,
    allSubscriptions: getAllSubscriptionProducts(data),
    upcomingRenewalSubscriptions
  };
};