import { useMemo } from 'react';
import { ACCOUNT_SUSPENDED_SCOPE, BILLING_READ_RESTRICTED_SCOPE, BILLING_READ_SCOPE, BILLING_WRITE_SCOPE, CONTENT_MIGRATIONS_STATUS_VIEW, MARKETABLE_CONTACTS_WRITE_SCOPE } from '../constants/scopes';
export const useHasAnyScope = (userScopes, scopesToAuthorize) => {
  return useMemo(() => scopesToAuthorize.some(scope => userScopes.includes(scope)), [userScopes, scopesToAuthorize]);
};
export const useIsBillingAdmin = userScopes => userScopes.includes(BILLING_WRITE_SCOPE);
export const useHasSensitiveBillingInfoAccess = userScopes => userScopes.includes(BILLING_READ_RESTRICTED_SCOPE);
export const useBillingReadAccess = userScopes => userScopes.includes(BILLING_READ_SCOPE);
export const useHasBillingAccess = userScopes => useHasAnyScope(userScopes, [BILLING_READ_SCOPE, BILLING_WRITE_SCOPE, BILLING_READ_RESTRICTED_SCOPE]);
export const useHasMigrationsViewAccess = userScopes => userScopes.includes(CONTENT_MIGRATIONS_STATUS_VIEW);
export const useIsAuthorized = userScopes => useHasAnyScope(userScopes, [MARKETABLE_CONTACTS_WRITE_SCOPE, BILLING_READ_SCOPE]);
export const useIsSuspended = userScopes => userScopes.includes(ACCOUNT_SUSPENDED_SCOPE);
export const useHasGate = (userGates, gate) => {
  return useMemo(() => userGates.includes(gate), [userGates, gate]);
};