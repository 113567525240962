/* eslint-disable @typescript-eslint/no-misused-promises */

import { onResponseError } from 'hub-http/middlewares/core';
import { ApiError } from '../utils/ApiError';
import { getIn } from '../utils/getIn';
import { logError } from '../utils/logError';
const getCorrelationId = getIn(['headers', 'x-hubspot-correlation-id']);
export const sendRavenOnResponseError = (options = {}) => onResponseError(error => {
  const ravenOptions = getIn(['ravenOptions'], options, {});
  const {
    enabled = true,
    tags,
    extra = {}
  } = ravenOptions;
  const correlationId = getCorrelationId(error, '');
  if (enabled) {
    logError({
      error: ApiError.fromRestError({
        request: options,
        error
      }),
      tags,
      extra,
      correlationId,
      fingerprint: ['{{ message }}']
    });
  }
  return Promise.reject(error);
})(options);