'use es6';

import PropTypes from 'prop-types';
import OutageReasonPropType from './OutageReasonPropType';
const ServiceAvailabilityStatusPropType = PropTypes.shape({
  isDown: PropTypes.bool.isRequired,
  outageReason: OutageReasonPropType,
  outageStartTime: PropTypes.number,
  outageEndTime: PropTypes.number
});
export default ServiceAvailabilityStatusPropType;