import { selectDataSourcesPropertiesStatus, fetchDataSourcesProperties, selectDataSourcesByPropertyRecords } from '../ducks/dataSourcePropertiesSlice';
import { useEffect } from 'react';
import { RequestState } from '../filter/requests/request-state';
import { usePlatformDashboardDispatch, usePlatformDashboardSelector } from '../store';
import { selectIfUserHasCustomReportAccess, selectUserScope } from '../ducks/userInfoSlice';
export const useDataSourceProperties = dataSourceIds => {
  const propertiesByDataSource = usePlatformDashboardSelector(selectDataSourcesByPropertyRecords(dataSourceIds));
  const propertyStatus = usePlatformDashboardSelector(selectDataSourcesPropertiesStatus(dataSourceIds));
  const hasCustomReportingAccess = usePlatformDashboardSelector(selectIfUserHasCustomReportAccess);
  const scopes = usePlatformDashboardSelector(selectUserScope);
  const dispatch = usePlatformDashboardDispatch();
  const isUninitialized = propertyStatus === RequestState.UNINITIALIZED;
  const isLoading = propertyStatus === RequestState.PENDING || isUninitialized;
  const isResolved = !isLoading;
  const hasFailed = propertyStatus === RequestState.FAILED;
  useEffect(() => {
    if (isUninitialized) {
      dispatch(fetchDataSourcesProperties(dataSourceIds, hasCustomReportingAccess, scopes));
    }
  }, [dataSourceIds, isUninitialized, dispatch, hasCustomReportingAccess, scopes]);
  return {
    propertiesByDataSource,
    isLoading,
    isUninitialized,
    isResolved,
    hasFailed
  };
};