import I18n from 'I18n';
import { ENUMERATION } from 'reporting-data/constants/property-types';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
const DAYS_14 = '1209600000';
const DAYS_30 = '2592000000';
const DAYS_60 = '5184000000';
const DAYS_90 = '7776000000';
const propertyValues = {
  'closedate>historical_net_change': [DAYS_14, DAYS_30, DAYS_60, DAYS_90]
};
const propertySubApps = {
  'closedate>historical_net_change': SUB_APPS.dealPushAnalysis
};
const getPropertyLang = propertyName => I18n.text(`settingLabels.quickFilter.subApp.${propertyName}.label`);
export const getPropertyOptions = propertyName => {
  const values = propertyValues[propertyName];
  if (!values) return [];
  return values.map(value => ({
    label: I18n.text(`settingLabels.quickFilter.subApp.${propertyName}.options.${value}`),
    value
  }));
};
const getFilterProperty = propertyName => ({
  type: ENUMERATION,
  label: getPropertyLang(propertyName),
  options: getPropertyOptions(propertyName),
  property: propertyName
});
const getPropertySubApp = propertyName => propertySubApps[propertyName] || [];
export const getSubAppFilterProperty = (subAppKey, propertyName) => {
  if (subAppKey && getPropertySubApp(propertyName).includes(subAppKey)) {
    return getFilterProperty(propertyName);
  }
  return null;
};