import { registerQuery, useQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../../clients/hubHttpWithRaven';
const GET_SANDBOX_DATA_QUERY = registerQuery({
  fieldName: 'sandboxInfoObject',
  args: ['portalId'],
  fetcher({
    portalId
  }) {
    return httpClientWithRaven.get(`sandbox-hubs/v1/self/?portalId=${portalId}`);
  }
});
export const useGetSandboxData = portalId => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_SANDBOX_DATA_QUERY, {
    variables: {
      portalId
    }
  });
  return {
    data: data === null || data === void 0 ? void 0 : data.sandboxInfoObject,
    loading,
    error
  };
};