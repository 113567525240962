import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { HD_SERVICE_ANALYTICS_SCOPE } from '../constants/scopes';
const REPORT_HYBRID_SRV_SUB_APP_DATA = {
  // MARKETING
  [SUB_APPS.ads]: {
    adNetworks: {
      tabKey: 'adNetworks'
    },
    adCampaigns: {
      tabKey: 'adCampaigns'
    }
  },
  [SUB_APPS.leadCapture]: {
    forms: {
      tabKey: 'forms'
    }
  },
  [SUB_APPS.marketingEmails]: {
    marketingEmails: {
      tabKey: 'marketingEmails'
    }
  },
  [SUB_APPS.sms]: {
    sms: {
      tabKey: 'sms'
    }
  },
  [SUB_APPS.cta]: {
    cta: {
      tabKey: 'cta'
    }
  },
  [SUB_APPS.customerJourneyAnalytics]: {
    // dealStageProgression: {
    //   tabKey: 'dealStageProgression',
    // },
    lifecycleStageProgression: {
      tabKey: 'lifecycleStageProgression'
    },
    pageVisitsToFormSubmissions: {
      tabKey: 'pageVisitsToFormSubmissions'
    },
    leadCreationBrokenDownByAdNetwork: {
      tabKey: 'leadCreationBrokenDownByAdNetwork'
    },
    meetingsThatGeneratedDeals: {
      tabKey: 'meetingsThatGeneratedDeals'
    }
  },
  [SUB_APPS.sources]: {
    sources: {
      tabKey: 'sources'
    }
  },
  [SUB_APPS.pages]: {
    pages: {
      tabKey: 'pages'
    }
  },
  [SUB_APPS.utmParameters]: {
    utmCampaign: {
      tabKey: 'utmCampaign'
    },
    utmSource: {
      tabKey: 'utmSource'
    },
    utmMedium: {
      tabKey: 'utmMedium'
    },
    utmContent: {
      tabKey: 'utmContent'
    },
    utmTerm: {
      tabKey: 'utmTerm'
    }
  },
  [SUB_APPS.deviceTypes]: {
    deviceTypes: {
      tabKey: 'deviceTypes'
    }
  },
  [SUB_APPS.geolocation]: {
    geolocation: {
      tabKey: 'geolocation'
    }
  },
  [SUB_APPS.browsers]: {
    browsers: {
      tabKey: 'browsers'
    }
  },
  [SUB_APPS.topicClusters]: {
    topicClusters: {
      tabKey: 'topicClusters'
    }
  },
  [SUB_APPS.contactInsights]: {
    contactInsights: {
      tabKey: 'contactInsights'
    }
  },
  [SUB_APPS.attribution]: {
    revenueAttribution: {
      tabKey: 'attribution'
    }
  },
  // SERVICE
  [SUB_APPS.ticketTimeToClose]: {
    ticketTimeToCloseAllHours: {
      tabKey: 'ticketTimeToRespondAllHours'
    },
    ticketTimeToCloseSLAHours: {
      tabKey: 'ticketTimeToRespondSLAHours',
      lockedScope: HD_SERVICE_ANALYTICS_SCOPE
    }
  },
  [SUB_APPS.ticketTimeToRespond]: {
    ticketTimeToRespondAllHours: {
      tabKey: 'ticketTimeToRespondAllHours'
    },
    ticketTimeToRespondSLAHours: {
      tabKey: 'ticketTimeToRespondSLAHours',
      lockedScope: HD_SERVICE_ANALYTICS_SCOPE
    }
  },
  [SUB_APPS.customerEffortScore]: {
    customerEffortScore: {
      tabKey: 'customerEffortScore'
    }
  },
  [SUB_APPS.customerReplyTime]: {
    averageCustomerReplyTime: {
      tabKey: 'averageCustomerReplyTime'
    },
    customersWithHighestAverageReplyTime: {
      tabKey: 'customersWithHighestAverageReplyTime'
    }
  },
  [SUB_APPS.customerWaitTime]: {
    customerWaitTime: {
      tabKey: 'customerWaitTime'
    }
  },
  [SUB_APPS.ownerReplyCount]: {
    ownerReplyCount: {
      tabKey: 'ownerReplyCount'
    }
  },
  [SUB_APPS.ownerReplyTime]: {
    averageReplyTime: {
      tabKey: 'averageReplyTime'
    },
    averageReplyTimeByOwner: {
      tabKey: 'averageReplyTimeByOwner'
    }
  },
  [SUB_APPS.ticketReplyCount]: {
    ticketsWithMostMessagesSent: {
      tabKey: 'ticketsWithMostMessagesSent'
    },
    ticketsWithMostMessagesReceived: {
      tabKey: 'ticketsWithMostMessagesReceived'
    }
  },
  [SUB_APPS.timeOfDay]: {
    messagesSentByTimeOfDay: {
      tabKey: 'messagesSentByTimeOfDay'
    },
    messagesReceivedByTimeOfDay: {
      tabKey: 'messagesReceivedByTimeOfDay'
    }
  },
  [SUB_APPS.workingHoursAvailability]: {
    workingHoursAvailability: {
      tabKey: 'workingHoursAvailability'
    }
  },
  [SUB_APPS.maxAvailableRep]: {
    maxAvailableRep: {
      tabKey: 'maxAvailableRep'
    }
  },
  [SUB_APPS.repAvailability]: {
    repAvailabilityTopLevel: {
      tabKey: 'repAvailabilityTopLevel'
    },
    repAvailabilityDrilldown: {
      tabKey: 'repAvailabilityDrilldown'
    }
  }
};
export const getHybridSRVSubAppData = subAppKey => REPORT_HYBRID_SRV_SUB_APP_DATA[subAppKey];
export const isSubAppHybridSRVReport = subAppKey => !!getHybridSRVSubAppData(subAppKey);
export const getHybridSRVSubAppTabs = (subAppKey, scopes, gates, includeLocked = false) => {
  const subAppData = getHybridSRVSubAppData(subAppKey) || {};
  return Object.keys(subAppData).filter(tabKey => {
    const tabData = subAppData[tabKey];
    const requiredScope = tabData.scope;
    const requiredGate = tabData.gate;
    const lockedScope = tabData.lockedScope;
    return (!requiredScope || scopes.includes(requiredScope)) && (!requiredGate || gates.includes(requiredGate)) && (includeLocked || !lockedScope || scopes.includes(lockedScope));
  });
};
export const getTabLockedScope = (subAppKey, tabKey) => {
  const subAppData = getHybridSRVSubAppData(subAppKey);
  if (!subAppData) {
    return null;
  }
  const tabData = subAppData[tabKey];
  return tabData.lockedScope;
};