import * as ErrorTypes from '../constants/errorTypes';
import { TooMuchDataException, InvalidPropertiesException, DeprecatedPropertyException, UnsupportedException, TooManyMetricsException, TooManyDealStagesException, UnsupportedDateRangeException, MissingRequiredDataException, TooLargeDatasetException, InvalidPipelineException, NoDimensionsOrMetricsException, DataReprocessingException, HighchartsErrorException, TooManyBreakdownsException, MissingIntegrationException, MissingSeriesException, PipelineCadenceMismatchException, AccessDeniedException, TimeoutException, InvalidTwoDimensionMetricException, FunnelCountPropertyNotPresent, TooManySequentialOptionalStages, UnfilteredPipelineStage, FieldLevelPermission, UnsupportedDateRangeForAttributionReportsException } from '../exceptions';
export default (error => {
  const {
    status,
    statusText,
    errorCode,
    responseJSON: {
      type,
      errorType,
      message
    } = {}
  } = error || {};
  if (status === 403 || error instanceof AccessDeniedException) {
    // @ts-expect-error I think we need to revisit if this is intentional
    return ErrorTypes[errorType] || ErrorTypes.ACCESS_DENIED;
  }
  if (status === 413 || statusText === ErrorTypes.REQUEST_ENTITY_TOO_LARGE || type === ErrorTypes.RESULT_POINT_LIMIT_EXCEEDED || error instanceof TooMuchDataException) {
    return ErrorTypes.TOO_MANY_POINTS_REQUESTED;
  }
  if (status === 400 && typeof message === 'string' && message.includes('should be the same as or earlier than today')) {
    return ErrorTypes.FUTURE_DATE_UNSUPPORTED;
  }
  if (status === 400 && typeof message === 'string' && message.includes('filters are required for queries by points')) {
    return ErrorTypes.MISSING_UNIFIED_ANALYTICS_FILTER;
  }
  if (error instanceof TooManyBreakdownsException) {
    /**
     * This error is hit when the breakdown filter is empty when the breakdown is set in these UA templates:
     * forms, ads, sources, pages, UTM ones, device types, browsers, countries, topic clusters
     */
    return ErrorTypes.TOO_MANY_BREAKDOWNS;
  }
  if (errorCode === ErrorTypes.TIMEOUT || error instanceof TimeoutException) {
    return ErrorTypes.TIMEOUT;
  }
  if (error instanceof InvalidPropertiesException) {
    return ErrorTypes.INVALID_PROPERTIES;
  }
  if (error instanceof DeprecatedPropertyException) {
    return ErrorTypes.DEPRECATED_PROPERTY;
  }
  if (error instanceof FunnelCountPropertyNotPresent) {
    return ErrorTypes.FUNNEL_COUNT_PROPERTY_NOT_PRESENT;
  }
  if (error instanceof UnsupportedException) {
    return ErrorTypes.UNSUPPORTED;
  }
  if (error instanceof TooManyMetricsException) {
    return ErrorTypes.TOO_MANY_METRICS_REQUESTED;
  }
  if (error instanceof TooManyDealStagesException) {
    return ErrorTypes.TOO_MANY_DEAL_STAGES_REQUESTED;
  }
  if (error instanceof UnsupportedDateRangeException) {
    return ErrorTypes.UNSUPPORTED_DATE_RANGE_REQUESTED;
  }
  if (error instanceof MissingRequiredDataException) {
    return ErrorTypes.MISSING_REQUIRED_DATA;
  }
  if (error instanceof InvalidPipelineException) {
    return ErrorTypes.INVALID_PIPELINE;
  }
  if (status === 422 || error instanceof TooLargeDatasetException) {
    if (error instanceof PipelineCadenceMismatchException || error.responseText && error.responseText.includes('Forecasting cadences must be uniform')) {
      return ErrorTypes.PIPELINE_CADENCE_MISMATCH;
    }
    return ErrorTypes.REQUEST_DATASET_TOO_LARGE;
  }
  if (error instanceof NoDimensionsOrMetricsException) {
    return ErrorTypes.MISSING_DIMENSIONS_AND_METRICS;
  }
  if (error instanceof DataReprocessingException) {
    return ErrorTypes.DATA_REPROCESSING;
  }
  if (error instanceof HighchartsErrorException) {
    return ErrorTypes.HIGHCHARTS_ERROR;
  }
  if (error instanceof MissingIntegrationException) {
    return ErrorTypes.MISSING_INTEGRATION_ERROR;
  }
  if (error instanceof MissingSeriesException) {
    return ErrorTypes.MISSING_SERIES_ERROR;
  }
  if (error instanceof InvalidTwoDimensionMetricException) {
    return ErrorTypes.INVALID_TWO_DIMENSION_METRIC;
  }
  if (error instanceof TooManySequentialOptionalStages) {
    return ErrorTypes.TOO_MANY_SEQUENTIAL_OPTIONAL_STAGES;
  }
  if (error instanceof UnfilteredPipelineStage) {
    return ErrorTypes.UNFILTERED_PIPELINE_STAGE;
  }
  if (error instanceof FieldLevelPermission) {
    return ErrorTypes.FIELD_LEVEL_PERMISSIONS;
  }
  if (error instanceof UnsupportedDateRangeForAttributionReportsException) {
    return ErrorTypes.UNSUPPORTED_DATE_RANGE_FOR_ATTRIBUTION_REPORTS;
  }
  return ErrorTypes.UNKNOWN;
});