import { getReport } from '../../filter/utils/schema/widgetSchema';
import { createAsyncThunk } from '@reduxjs/toolkit';
// @ts-expect-error ts-migrate(2307)
import bust from 'reporting-data/bust';
import { getReportRefreshPromise } from '../../data/requests/report';
import { getId as getReportId } from '../../filter/utils/schema/reportSchema';
import { fetchResource } from '../dashboardDataSlice';
export const refreshAllReportsOnDashboardAsyncThunk = createAsyncThunk('dashboard/refresh', async ({
  dashboard,
  resourceId,
  resourceType
}) => {
  try {
    bust();
    await Promise.allSettled(dashboard.widgets.map(getReport).map(report => getReportRefreshPromise(report, getReportId(report))));
    const fetchResourceFunc = fetchResource[resourceType];
    if (!fetchResourceFunc) {
      return dashboard;
    }
    const updatedDashboard = await fetchResourceFunc(resourceId);
    return updatedDashboard;
  } catch (error) {
    return error;
  }
});