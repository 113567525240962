import { RelationalReport } from '../../schema/report-records';
import { Map as ImmutableMap } from 'immutable';
import { useCallback, useState } from 'react';
import { ReportDeps } from '../../metadata/report-deps';
import { addColumnToChannel, updateEncoding, updateVisualType
//@ts-expect-error Untyped import
} from '.';
import { useReportMeta } from '../../metadata/report-meta';
import { isRelationalReport } from 'reporting-data/tsTypes/reportTypes';
import { userInfo as fetchUserInfo } from 'reporting-data/request/user-info';
import { useAsyncFetcher } from 'reporting-data/asyncData/useAsyncFetcher';
import { isSucceeded } from 'reporting-data/asyncData/AsyncData';
export let SnowflakeReportUpdateActions;
(function (SnowflakeReportUpdateActions) {
  SnowflakeReportUpdateActions["ADD_COLUMN_TO_CHANNEL"] = "ADD_COLUMN_TO_CHANNEL";
  SnowflakeReportUpdateActions["UPDATE_ENCODING"] = "UPDATE_ENCODING";
  SnowflakeReportUpdateActions["UPDATE_VISUAL_TYPE"] = "UPDATE_VISUAL_TYPE";
})(SnowflakeReportUpdateActions || (SnowflakeReportUpdateActions = {}));
const convertReportMapToRelationalReport = report => {
  if (isRelationalReport(report)) {
    return RelationalReport(report.get('reportDefinition').toJS());
  } else {
    throw new Error('Report is not a RelationalReport');
  }
};
const useFetchUserInfo = () => {
  const fetcher = useCallback(() => fetchUserInfo(), []);
  const userInfoFetch = useAsyncFetcher({
    fetcher
  });
  if (isSucceeded(userInfoFetch)) {
    return userInfoFetch.data;
  }
};
export const useSnowflakeReportUpdater = report => {
  const reportDefinition = ImmutableMap.isMap(report) ? convertReportMapToRelationalReport(report) : report;
  const [currentReport, setCurrentReport] = useState(reportDefinition);
  const userInfo = useFetchUserInfo();
  const {
    reportWithMeta,
    error: reportWithMetaError
  } = useReportMeta(currentReport);
  if (reportWithMetaError || !reportWithMeta) {
    return () => {
      return {
        currentReport,
        error: reportWithMetaError || 'Report meta could not be loaded'
      };
    };
  }
  const deps = ReportDeps({
    meta: reportWithMeta.reportMeta,
    userInfo
  });
  return (reportUpdateAction, props) => {
    let nextReport = currentReport;
    switch (reportUpdateAction) {
      case SnowflakeReportUpdateActions.ADD_COLUMN_TO_CHANNEL:
        nextReport = addColumnToChannel(reportDefinition, Object.assign({
          deps
        }, props));
        break;
      case SnowflakeReportUpdateActions.UPDATE_ENCODING:
        nextReport = updateEncoding(reportDefinition, Object.assign({
          deps
        }, props));
        break;
      case SnowflakeReportUpdateActions.UPDATE_VISUAL_TYPE:
        nextReport = updateVisualType(reportDefinition, Object.assign({
          deps
        }, props));
        break;
      default:
        break;
    }
    setCurrentReport(nextReport);
    return {
      report: nextReport,
      error: null
    };
  };
};