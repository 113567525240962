import { isFunction } from './function';
import { isNonEmptyList } from './list';
function _get(pathToKey, subject, defaultValue) {
  if (!subject) {
    return defaultValue;
  }
  let value = subject;
  for (const key of pathToKey) {
    value = isFunction(value[Symbol.iterator]) && isFunction(value.get) ? value.get(key) : value[key];
    if (value === undefined || value === null) {
      return defaultValue;
    }
  }
  return value;
}
export function getIn(pathToKey, subject, defaultValue) {
  if (!isNonEmptyList(pathToKey)) {
    throw new Error(`getIn: expected path to key to be an array but got '${String(pathToKey)}'`);
  }
  if (arguments.length >= 2) return _get(pathToKey, subject, defaultValue);
  return (inputSubject, inputDefaultValue) => _get(pathToKey, inputSubject, inputDefaultValue);
}