import toJS from 'reporting-data/lib/toJS';
import { post } from 'reporting-data/request/http';
import { exportJourneyReport } from './export';
const requestJourneyExport = exportRequest => post('reporting-platform/v1/journey-report/export', {
  data: exportRequest
}).then(toJS);
export const exportJourney = (report, exportOptions) => {
  const requestBody = exportJourneyReport(report.toJS(), exportOptions);
  return requestJourneyExport(requestBody).catch(error => {
    console.error(error);
    throw error;
  });
};