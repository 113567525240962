import { fromJS } from 'immutable';
import { isRelationalReport, isSingleObjectReport } from 'reporting-data/tsTypes/reportTypes';
import { SnowflakeReportUpdateActions } from 'reporting-snowflake/relational/updaters/report/use-snowflake-updater';
import getMetricLabel from 'reporting-data/public/get-metric-label';
export let BandType;
(function (BandType) {
  BandType["MIN"] = "min";
  BandType["MAX"] = "max";
  BandType["MID"] = "mid";
})(BandType || (BandType = {}));
export const getIsReportValidForConditionalFormatting = ({
  report
}) => {
  return isSingleObjectReport(report) || isRelationalReport(report);
};
export const updateSnowflakeReportEncodingWithConditionalFormatting = ({
  toIndex,
  updatedEncoding,
  snowflakeUpdater
}) => {
  const result = snowflakeUpdater(SnowflakeReportUpdateActions.UPDATE_ENCODING, {
    encoding: updatedEncoding,
    channelName: 'columns',
    toIndex
  });
  if (result.error) {
    throw new Error(result.error);
  }
  return result.report;
};
export const applyConditionalFormattingToColumn = (report, dropdownColumn) => {
  var _report$visual;
  const encodings = (_report$visual = report.visual) === null || _report$visual === void 0 ? void 0 : _report$visual.encodings;
  const {
    columns
  } = report;
  const column = columns.get(dropdownColumn);
  const encoding = encodings.get('columns').find(encodedColumn => encodedColumn.column === column.get('alias'));
  const selectedColumnEncoding = encodings.get('columns').find(encodedColumn => encodedColumn.column === dropdownColumn);
  const existingConditionalFormatting = selectedColumnEncoding === null || selectedColumnEncoding === void 0 ? void 0 : selectedColumnEncoding.getIn(['options', 'format', 'conditionalFormatting']);
  if (existingConditionalFormatting !== null && existingConditionalFormatting !== void 0 && existingConditionalFormatting.get('useConditionalFormatting')) {
    return undefined;
  }
  const conditionalFormatting = {
    useConditionalFormatting: true
  };
  const updatedEncoding = encoding.setIn(['options', 'format', 'conditionalFormatting'], fromJS(conditionalFormatting));
  return updatedEncoding;
};
export const getConfigMetricOptions = (property, metric) => {
  const metricTypes = metric.get('metricTypes');
  if (!metricTypes || metricTypes.size === 0) {
    return [{
      value: property === null || property === void 0 ? void 0 : property.name,
      text: property === null || property === void 0 ? void 0 : property.label
    }];
  }
  return metricTypes.map(metricType => ({
    value: property === null || property === void 0 ? void 0 : property.name,
    text: getMetricLabel(property === null || property === void 0 ? void 0 : property.label, metricType)
  }));
};
export const applyConditionalFormattingToConfigColumn = (report, dropdownColumn) => {
  const existingConditionalFormatting = report.getIn(['displayParams', 'columns', dropdownColumn, 'format', 'conditionalFormatting']);
  if (existingConditionalFormatting !== null && existingConditionalFormatting !== void 0 && existingConditionalFormatting.get('useConditionalFormatting')) {
    return undefined;
  }
  const conditionalFormatting = {
    useConditionalFormatting: true
  };
  return report.setIn(['displayParams', 'columns', dropdownColumn, 'format', 'conditionalFormatting'], fromJS(conditionalFormatting));
};