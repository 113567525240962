import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getReportDataSourceUsages } from '../../lib/dashboardFilters/dataSourceHelpers';
import { transformDashboardFilterToSavedFilter, transformSavedFilterToDashboardFilter } from '../../lib/savedDashboardFilters';
import { selectDashboardFilters, selectDashboardFiltersWithMaybeInvalidProperties, selectDashboardFrequency, selectDashboardFrequencyUseFiscalYear } from '../applied-dashboard-filters';
import { selectBackendAppliedFiltersForReport, selectDashboardReportsAsyncStatus, selectOverriddenDashboardReport } from './selectors';
import { getOverriddenReportsFromAPIThunk } from './thunks';
import { useDataSourceMetaWithLoading } from 'platform-dashboard-ui/hooks/use-data-source-metadata';
import { useDataSourceProperties } from 'platform-dashboard-ui/hooks/use-data-source-properties';
import { selectIsQuickFiltersInitialized, selectQuickFilterGroups } from '../quick-filters';
import { checkIfQuickFilterGroupIsApplied, isDateRangeGeneratedQuickFilterGroup } from '../../lib/dashboardFilters/groupQuickFilterHelpers';
import { DYNAMIC_DATE_RANGE_PROPERTY_NAME } from '../../lib/dashboardFilters/types';
import { usePrevious } from '../../lib/usePrevious';
import { fromJS } from 'immutable';

// if date range group is applied, simplify payload to only include the dyanmic date range filter
export const useBackendFilterPayload = dashboardId => {
  const appliedFilters = useSelector(selectDashboardFilters(dashboardId));
  const quickFilters = useSelector(selectQuickFilterGroups);
  const appliedFiltersWithInvalidProperties = useSelector(selectDashboardFiltersWithMaybeInvalidProperties(dashboardId));
  const isQuickFiltersInitialized = useSelector(selectIsQuickFiltersInitialized);
  const dataSourceIds = Array.from(new Set(quickFilters.map(q => {
    var _q$invalidProperties;
    return q.properties.map(p => p.dataSourceId).concat(((_q$invalidProperties = q.invalidProperties) === null || _q$invalidProperties === void 0 ? void 0 : _q$invalidProperties.map(p => p.dataSourceId)) || []);
  }).flat().concat(appliedFiltersWithInvalidProperties.map(f => f.dataSource))));
  const {
    isLoading: dataSourcePropertiesAreLoading
  } = useDataSourceProperties(dataSourceIds);
  const appliedFrequency = useSelector(selectDashboardFrequency(dashboardId));
  const appliedFrequencyFiscalYear = useSelector(selectDashboardFrequencyUseFiscalYear(dashboardId));
  const maybeDateRangeQuickFilter = quickFilters.find(isDateRangeGeneratedQuickFilterGroup);
  const isDateRangeQuickFilterApplied = maybeDateRangeQuickFilter && checkIfQuickFilterGroupIsApplied(maybeDateRangeQuickFilter, appliedFilters);
  const isDateRangeQuickFilterValid = maybeDateRangeQuickFilter && maybeDateRangeQuickFilter.properties.length > 0;
  const transformedFilters = (() => {
    const apiFilters = transformDashboardFilterToSavedFilter(appliedFilters);
    if (!(isDateRangeQuickFilterApplied && isDateRangeQuickFilterValid)) {
      return apiFilters;
    }
    // replace filters with property in date range quick filter with a single date range filter
    const apiFiltersMinusDateRangeFilter = apiFilters.filter(filter => {
      return !maybeDateRangeQuickFilter.properties.some(property => filter.dataSourceId === property.dataSourceId && filter.name === property.name);
    });
    const dateRangeFilters = apiFilters.filter(filter => {
      return maybeDateRangeQuickFilter.properties.some(property => filter.dataSourceId === property.dataSourceId && filter.name === property.name);
    });
    const dateRangeFilter = Object.assign({}, dateRangeFilters[0], {
      dataSourceId: undefined,
      name: DYNAMIC_DATE_RANGE_PROPERTY_NAME
    });
    return [dateRangeFilter, ...apiFiltersMinusDateRangeFilter];
  })();
  if (dataSourcePropertiesAreLoading || !isQuickFiltersInitialized) {
    return {
      filters: [],
      dashboardId
    };
  }
  return {
    // TODO add dyanamic date range filter as allowed type
    filters: transformedFilters,
    frequency: appliedFrequency,
    useFiscalYear: appliedFrequencyFiscalYear,
    dashboardId
  };
};
const hasBEPayloadChanged = (current, previous) => !fromJS(current).equals(fromJS(previous));
const hasBEFiltersApplied = payload => payload.filters.length > 0 || payload.frequency != null;
export const useDashboardReportsOverrides = dashboardId => {
  const overriddenDashboardReportsAsyncStatus = useSelector(selectDashboardReportsAsyncStatus(dashboardId));
  const dispatch = useDispatch();
  const currentAppliedFiltersBackendPayload = useBackendFilterPayload(dashboardId);
  const previousAppliedFiltersBackendPayload = usePrevious(currentAppliedFiltersBackendPayload);
  useEffect(() => {
    const payloadsDifferentThanPrevious = hasBEPayloadChanged(currentAppliedFiltersBackendPayload, previousAppliedFiltersBackendPayload);
    const previousPayloadHadFilters = previousAppliedFiltersBackendPayload && hasBEFiltersApplied(previousAppliedFiltersBackendPayload);
    const currentPayloadHasFilters = hasBEFiltersApplied(currentAppliedFiltersBackendPayload);
    const isNotEmptyInitCall = previousPayloadHadFilters || currentPayloadHasFilters;
    if (isNotEmptyInitCall && payloadsDifferentThanPrevious) {
      try {
        dispatch(getOverriddenReportsFromAPIThunk(currentAppliedFiltersBackendPayload)).catch(error => {
          console.error(error);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [currentAppliedFiltersBackendPayload, dispatch, previousAppliedFiltersBackendPayload, overriddenDashboardReportsAsyncStatus]);
};
export const useGetBackendAppliedFiltersForReport = (dashboardId, reportId) => {
  const maybeOverriddenReport = useSelector(selectOverriddenDashboardReport(dashboardId, reportId));
  const maybeOverriddenFilters = useSelector(selectBackendAppliedFiltersForReport(dashboardId, reportId));
  const reportDataSourceUsages = getReportDataSourceUsages({
    reports: maybeOverriddenReport ? [maybeOverriddenReport] : []
  });
  const reportDataSourceIds = Object.keys(reportDataSourceUsages);
  const {
    propertiesByDataSource: dataSourcesProperties
  } = useDataSourceProperties(reportDataSourceIds);
  const {
    dataSourceMetaData: dataSourcesMetadata
  } = useDataSourceMetaWithLoading(reportDataSourceIds);
  const transformedOverriddenFilters = (() => {
    try {
      return maybeOverriddenFilters && maybeOverriddenFilters.length > 0 ? transformSavedFilterToDashboardFilter(maybeOverriddenFilters, dataSourcesProperties, dataSourcesMetadata) : [];
    } catch (error) {
      return [];
    }
  })();
  return transformedOverriddenFilters;
};