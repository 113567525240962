import { registerQuery } from 'data-fetching-client';
import { httpClientWithRaven } from '../clients/hubHttpWithRaven';
export const GET_DOES_DOCUMENT_EXIST = registerQuery({
  args: ['type', 'recordId'],
  fieldName: 'documentExistsObject',
  fetcher({
    type,
    recordId
  }) {
    return httpClientWithRaven.get(`transactions-experience/v1/documents/${type}/${recordId}/exists`);
  }
});