import { createStandardCachedResolver } from './createStandardCachedResolver';
import { getReferenceTypeFromExternalOptionsProperty } from '../internal/getReferenceTypeFromExternalOptionsProperty';
const getAdditionalConfigFromPropertyOrPropertyDefinition = options => {
  if ('objectTypeId' in options) {
    const {
      property,
      objectTypeId
    } = options;
    return {
      objectTypeId,
      propertyName: property.name,
      externalOptionsMetaData: undefined
    };
  }
  const {
    property
  } = options;
  return {
    objectTypeId: property.objectTypeId,
    propertyName: property.property.name,
    externalOptionsMetaData: property.externalOptionsMetaData || undefined
  };
};

/**
 * Until all CRM property definitions contain the
 * `externalOptionsReferenceType` field, use this function to
 * map properties (given an objectTypeId) to the right resolver.
 *
 * Note: this only works with objectTypeIds with metaTypeId=0.
 * This is because objectTypeId is not the same in PROD and QA
 * for all other metaTypeIds.
 *
 * @param {Object} property                     CRM property definition object
 * @param {string} objectTypeId                 Object Type ID in the form `metaTypeId-typeId` (i.e. 0-1)
 * @param {Object} httpClient                   (optional) httpClient to forward to resolver
 * NOTE: the `httpClient` option only applies the first time a resolver is requested for
 * a `objectTypeId/referenceType` pair. Subsequent calls will return the cached
 * resolver instead.
 *
 * @returns             `ReferenceResolver` to use for resolving values
 * of the given property, or `null` if no resolver should be used
 */
export const getPropertyResolver = options => {
  const {
    httpClient,
    searchIncludeDeleted,
    property
  } = options;

  // HACK: The types say this shouldn't be possible, but there are some consumers who may be calling
  // us from js. If we ever get to a point where all consumers are in TS, we can remove this check.
  if (!property) {
    return null;
  }
  const {
    objectTypeId,
    propertyName,
    externalOptionsMetaData
  } = getAdditionalConfigFromPropertyOrPropertyDefinition(options);
  const referenceType = getReferenceTypeFromExternalOptionsProperty(property, objectTypeId);
  if (!referenceType) {
    return null;
  }
  return createStandardCachedResolver(referenceType, objectTypeId, Object.assign({
    httpClient,
    searchIncludeDeleted,
    propertyName
  }, externalOptionsMetaData && {
    externalOptionsMetaData
  }));
};