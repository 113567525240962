import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["dashboardId"];
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDashboardWithFiltersApplied } from 'platform-dashboard-ui/data/dashboard-dao';
export const getOverriddenReportsFromAPIThunk = createAsyncThunk('getOverriddenReportsFromAPI/fetch', action => {
  try {
    const {
        dashboardId
      } = action,
      params = _objectWithoutPropertiesLoose(action, _excluded);
    return fetchDashboardWithFiltersApplied(dashboardId, params);
  } catch (error) {
    return Promise.reject(error);
  }
});