import { List } from 'immutable';
import I18n from 'I18n';
import { SUB_APPS } from 'reporting-data/tsTypes/subAppTypes';
import { DEFAULT_STORY_REPORTS } from 'reporting-enablement/components/pseudo-story-insights/types/storyReportTypes';
import { DIMENSIONS } from './salesAnalyticsHelpers';
export const BASE_QUESTION_CONTEXT_UPDATES = {
  dateRange: {
    rangeType: 'THIS_QUARTER',
    entireCurrentUnit: true
  }
};
export const QUESTIONS = {
  adNetworkPerformance: {
    questionId: 'adNetworkPerformance',
    subApp: SUB_APPS.ads,
    path: '/templates/marketing/ads',
    contextOverrides: {
      chartMetrics: List(['SUM|clicks'])
    }
  },
  sourcesSessions: {
    questionId: 'sourcesSessions',
    subApp: SUB_APPS.sources,
    path: '/templates/marketing/sources',
    insightType: DEFAULT_STORY_REPORTS.MAX,
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      chartMetrics: List(['SUM|visits'])
    }
  },
  pagesBounceRate: {
    questionId: 'pagesBounceRate',
    subApp: SUB_APPS.pages,
    path: '/templates/marketing/pages',
    insightType: DEFAULT_STORY_REPORTS.MAX,
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      chartMetrics: List(['PERCENTILES|pageBounceRate'])
    }
  },
  deviceTypesBounceRate: {
    questionId: 'deviceTypesBounceRate',
    subApp: SUB_APPS.deviceTypes,
    path: '/templates/marketing/deviceTypes',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      chartMetrics: List(['PERCENTILES|bounceRate'])
    }
  },
  commonDeviceTypes: {
    questionId: 'commonDeviceTypes',
    subApp: SUB_APPS.deviceTypes,
    path: '/templates/marketing/deviceTypes',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      chartMetrics: List(['SUM|contacts'])
    }
  },
  countriesCustomerConversion: {
    questionId: 'countriesCustomerConversion',
    subApp: SUB_APPS.geolocation,
    path: '/templates/marketing/geolocation',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      chartMetrics: List(['PERCENTILES|contactToCustomerRate'])
    }
  },
  browsersSessionLength: {
    questionId: 'browsersSessionLength',
    subApp: SUB_APPS.browsers,
    path: '/templates/marketing/browsers',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      chartMetrics: List(['PERCENTILES|timePerSession'])
    }
  },
  formsSubmissions: {
    questionId: 'formsSubmissions',
    subApp: SUB_APPS.leadCapture,
    path: '/templates/marketing/leadCapture',
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      chartMetrics: List(['SUM|submissions'])
    }
  },
  submissionConversion: {
    questionId: 'submissionConversion',
    subApp: SUB_APPS.leadCapture,
    path: '/templates/marketing/pages',
    insightType: DEFAULT_STORY_REPORTS.MAX,
    contextOverrides: {
      grouping: DIMENSIONS.ID,
      chartMetrics: List(['PERCENTILES|submissionsPerFormView'])
    }
  },
  contactsBySource: {
    questionId: 'contactsBySource',
    subApp: SUB_APPS.contactInsights,
    path: '/templates/marketing/contactInsights',
    contextOverrides: {
      grouping: DIMENSIONS.SOURCE,
      chartMetrics: List(['SUM|contacts'])
    }
  },
  contactsLifecycleStages: {
    questionId: 'contactsLifecycleStages',
    subApp: SUB_APPS.contactInsights,
    path: '/templates/marketing/contactInsights',
    contextOverrides: {
      grouping: DIMENSIONS.LIFE_CYCLE
    }
  },
  dealsTimeToClose: {
    questionId: 'dealsTimeToClose',
    subApp: SUB_APPS.dealVelocity,
    path: '/templates/sales/dealVelocity',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  forecastClosedWon: {
    questionId: 'forecastClosedWon',
    subApp: SUB_APPS.manualForecastTotal,
    path: '/templates/sales/manualForecastTotal',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  salesRepQuotaAttainment: {
    questionId: 'salesRepQuotaAttainment',
    subApp: SUB_APPS.quotaAttainment,
    path: '/templates/sales/quotaAttainment',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  callCountAndSuccess: {
    questionId: 'callCountAndSuccess',
    subApp: SUB_APPS.callOutcomes,
    path: '/templates/sales/callOutcomes',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  repConversations: {
    questionId: 'repConversations',
    subApp: SUB_APPS.conversations,
    path: '/templates/sales/conversations',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  highestActivityCount: {
    questionId: 'highestActivityCount',
    subApp: SUB_APPS.activities,
    path: '/templates/sales/activities',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  dealsCreated: {
    questionId: 'dealsCreated',
    subApp: SUB_APPS.dealsCreatedByRep,
    path: '/templates/sales/dealsCreatedByRep',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  fastestLeadResponseTime: {
    questionId: 'fastestLeadResponseTime',
    subApp: SUB_APPS.leadResponseTime,
    path: '/templates/sales/leadResponseTime',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  completedMeetings: {
    questionId: 'completedMeetings',
    subApp: SUB_APPS.meetingOutcomes,
    path: '/templates/sales/meetingOutcomes',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  mostTasksCompleted: {
    questionId: 'mostTasksCompleted',
    subApp: SUB_APPS.taskOutcomes,
    path: '/templates/sales/taskOutcomes',
    contextOverrides: {
      grouping: DIMENSIONS.OWNER
    }
  },
  // TODO: clean up when help desk operating hours (hd:insights:operating:hours) are released
  averageTimeToCloseTicket: {
    questionId: 'averageTimeToCloseTicket',
    subApp: SUB_APPS.resolutionTime,
    path: '/templates/service/resolutionTime',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  avgTimeToCloseTicket: {
    questionId: 'avgTimeToCloseTicket',
    subApp: SUB_APPS.ticketTimeToClose,
    path: '/templates/service/ticketTimeToClose',
    contextOverrides: {
      grouping: DIMENSIONS.DATE,
      tab: 'ticketTimeToCloseAllHours'
    }
  },
  timeToAssignChat: {
    questionId: 'timeToAssignChat',
    subApp: SUB_APPS.queueTime,
    path: '/templates/service/queueTime',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  ticketsByCategory: {
    questionId: 'ticketsByCategory',
    subApp: SUB_APPS.supportVolume,
    path: '/templates/service/supportVolume',
    contextOverrides: {}
  },
  customerSatisfaction: {
    questionId: 'customerSatisfaction',
    subApp: SUB_APPS.postSupportCSAT,
    path: '/templates/service/postSupportCSAT',
    contextOverrides: {}
  },
  ticketTimeInPipeline: {
    questionId: 'ticketTimeInPipeline',
    subApp: SUB_APPS.ticketTimeInPipeline,
    path: '/templates/service/ticketTimeInPipeline',
    contextOverrides: {
      grouping: DIMENSIONS.DATE
    }
  },
  highestEmailOpen: {
    questionId: 'highestEmailOpen',
    subApp: SUB_APPS.prospectEngagement,
    path: '/templates/sales/prospectEngagement',
    contextOverrides: {
      tab: 'emails',
      grouping: DIMENSIONS.OWNER,
      chartMetrics: List(['SUM|hs_email_open_count'])
    }
  }
};

// used by questions on reports-list home pages
export const getQuestionCopy = questionId => I18n.text(`sales-analytics-lib.quickAnswers.${questionId}`);
export const getQuestionContextOverrides = questionId => {
  const questionData = QUESTIONS[questionId];
  if (!questionData) {
    return null;
  }
  return questionData.contextOverrides;
};