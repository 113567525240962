'use es6';

import { PropertyDisplayType } from '../DisplayTypes';
import { PropertyInputType } from '../InputTypes';
import { makeOperator } from './Operator';
import isString from 'transmute/isString';
import { DATE, DATE_TIME } from 'customer-data-objects/property/PropertyTypes';
export default makeOperator({
  displayType: PropertyDisplayType,
  inputType: PropertyInputType,
  name: 'After',
  values: [{
    name: 'value',
    isValid: isString
  }, {
    name: 'referencedPropertyType',
    isValid: value => value === DATE || value === DATE_TIME || value === undefined
  }]
});