import { useQuery } from 'data-fetching-client';
import { useThrowIfNoDataFetchingClient } from './useThrowIfNoDataFetchingClient';
import { GET_DOES_DOCUMENT_EXIST } from '../api/queries/doesDocumentExist';
export const useGetDoesDocumentExist = ({
  recordId,
  type,
  skip
}) => {
  var _data$documentExistsO;
  useThrowIfNoDataFetchingClient();
  const {
    data,
    loading,
    error
  } = useQuery(GET_DOES_DOCUMENT_EXIST, {
    skip,
    variables: {
      recordId,
      type
    }
  });
  const hasMigrationDocument = (_data$documentExistsO = data === null || data === void 0 ? void 0 : data.documentExistsObject.documentExists) !== null && _data$documentExistsO !== void 0 ? _data$documentExistsO : false;
  return {
    hasMigrationDocument,
    loading,
    error
  };
};