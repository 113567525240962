import Raven from 'raven-js';
import { ApiError } from './ApiError';
import { copyObject } from './copyObject';
import { getIn } from './getIn';
const excludedHttpStatusPatterns = [/^0$/, /401/, /404/, /5\d\d/];
const _ignoreRavenErrorsByStatus = error => {
  const isNullOrUndefined = value => value === null || value === undefined;
  let errorStatus = getIn(['status'], error);
  errorStatus = isNullOrUndefined(errorStatus) ? getIn(['statusCode'], error) : errorStatus;
  errorStatus = isNullOrUndefined(errorStatus) ? getIn(['networkError', 'cause', 'status'], error) : errorStatus;
  const isExcludedHttpErrors = excludedHttpStatusPatterns.some(pattern => pattern.test(errorStatus));
  return isExcludedHttpErrors;
};
const _ignoreRavenErrorsByType = error => {
  const errorType = getIn(['errorCode'], error, {});
  const errorCause = getIn(['networkError', 'cause'], error, {});
  const {
    timeout,
    offline
  } = errorCause;
  const isOffline = navigator ? !navigator.onLine : false;
  return errorType === 'TIMEOUT' || errorType === 'NETWORKERROR' && isOffline || timeout || offline;
};
const _ignoreErrorsFromRaven = error => _ignoreRavenErrorsByStatus(error) || _ignoreRavenErrorsByType(error);
const logError = ({
  error,
  tags,
  extra,
  correlationId = '',
  fingerprint
}) => {
  if (error instanceof ApiError && _ignoreErrorsFromRaven(error.error)) {
    return;
  }
  const errorExtraFields = copyObject(error, {
    excludeKeys: ['message', 'name', 'stack']
  });
  Raven.captureException(error, {
    tags: correlationId ? Object.assign({
      correlationId
    }, tags) : tags,
    extra: Object.assign({}, extra, {
      error: errorExtraFields
    }),
    fingerprint
  });
};
export { logError };