import { useCallback, useContext, useMemo } from 'react';
import { AuthContext } from '../context/auth';
import { checkGatesArePresent, checkHubSuspended, checkParcelIfOfType, checkUserHasRole } from '../utils/accessControlUtils';
export const useAuth = userInfo => {
  const result = useContext(AuthContext);
  const auth = result.userInfo || userInfo;
  if (!auth) {
    throw new Error('useAuthContext must be used within an AuthProvider or passed a userInfo prop');
  }
  const isUngatedFor = useCallback(gate => checkGatesArePresent(gate, auth), [auth]);
  const hasRole = useCallback(roleToCheck => checkUserHasRole(roleToCheck, auth), [auth]);
  const hasParcel = useCallback(parcel => checkParcelIfOfType(parcel, auth), [auth]);
  const isSuspended = useMemo(() => checkHubSuspended(auth), [auth]);
  return useMemo(() => ({
    userInfo: auth,
    isSuspended,
    hasRole,
    isUngatedFor,
    hasParcel
  }), [auth, isSuspended, hasRole, isUngatedFor, hasParcel]);
};