module.exports = {
  "transactionsInteraction": {
    "name": "transactions interaction",
    "class": "interaction",
    "properties": {
      "action": {
        "type": [
          "make a payment banner",
          "pay all invoices",
          "view invoice",
          "pay now"
        ]
      },
      "source": {
        "type": "string",
        "isOptional": true
      }
    },
    "namespace": "account-and-billing-ui"
  }
};