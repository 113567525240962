import { registerQuery } from 'data-fetching-client';
import enviro from 'enviro';
import { noAuthHttpClientWithRaven } from '../clients/hubHttpWithRaven';
export const GET_FINTECH_ZUORA_OUTAGE = registerQuery({
  fetcher({
    hubId
  }) {
    const isProd = enviro.isProd();
    const suffix = isProd ? '' : `?hubId=${hubId}`;
    return noAuthHttpClientWithRaven.get(`fintech-outages/v1/zuora${suffix}`, {
      ravenOptions: {
        message: 'Error fetching fintech outage status'
      }
    });
  },
  args: ['hubId'],
  fieldName: 'fintechZuoraOutageStatus'
});