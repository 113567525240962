import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { DATETIME_GOAL } from '../constants/customSeries';
import { CRM_OBJECT, EXTERNAL, UNIFIED_EVENTS_QL } from '../constants/dataTypes';
import { REPORT_GROUP, SEARCH, TIME_SERIES } from './../constants/configTypes';
export const getReportConfig = report => report.get('config', ImmutableMap({}));
export const getConfigTypeFromConfig = config => config.get('configType');
export const getConfigType = report => getConfigTypeFromConfig(getReportConfig(report));
export const isTimeSeriesReport = report => {
  return getConfigType(report) === TIME_SERIES;
};
export const isSearchReport = report => {
  return getConfigType(report) === SEARCH;
};
export const isReportGrouping = report => getConfigType(report) === REPORT_GROUP;
export const getDataTypeFromConfig = (config = ImmutableMap()) => {
  const dataType = config.get('dataType');
  if (dataType === EXTERNAL) {
    // In case of dataType EXTERNAL, externalDataTypeSource should have a value
    return config.get('externalDataTypeSource');
  }
  if (dataType === CRM_OBJECT) {
    return config.get('objectTypeId');
  }
  if (dataType === UNIFIED_EVENTS_QL) {
    return config.get('unifiedEventTypeName');
  }
  return dataType;
};
/**
 * Returns the calculated data type based on the dataType and objectTypeId
 */
export const getDataType = report => getDataTypeFromConfig(getReportConfig(report));

/**
 * Returns the dataType string from the config
 */
export const getRawDataType = report => report.getIn(['config', 'dataType']);
export const getObjectTypeId = report => report.getIn(['config', 'objectTypeId']);
export const getMetricsFromConfig = config => config.get('metrics', ImmutableList([]));
export const getMetrics = report => getMetricsFromConfig(getReportConfig(report));
export const getMetricPropertiesFromConfig = config => getMetricsFromConfig(config).map(metric => metric.get('property'));
export const getMetricProperties = report => getMetricPropertiesFromConfig(getReportConfig(report));
export const getCustomized = report => getReportConfig(report).get('customized');
export const getOffset = report => getReportConfig(report).get('offset');
export const getDimensionFromConfig = config => config.get('dimensions', ImmutableList([]));
export const getDimensions = report => getDimensionFromConfig(getReportConfig(report));
export const getFrequency = config => config.get('frequency');
export const getTemplate = config => config.get('template');
export const getSortFromConfig = config => config.get('sort');
export const getSort = report => getSortFromConfig(getReportConfig(report));

/** Filters */
export const getFilters = config => config.get('filters');
export const getReportDateProperty = report => report.getIn(['config', 'filters', 'dateRange', 'property']);
export const getCustomFilters = config => config.getIn(['filters', 'custom'], ImmutableList([]));
export const getCustomFilterProperties = config => getCustomFilters(config).map(filterConfig => filterConfig.get('property')).toList();
export const getDateRangeFilter = config => config.getIn(['filters', 'dateRange']);
export const getDateRangeFilterProperty = config => config.getIn(['filters', 'dateRange', 'property']);
export const getDateRangeFilterValue = config => config.getIn(['filters', 'dateRange', 'value']);
export const getDateRangeFilterValueRangeType = config => config.getIn(['filters', 'dateRange', 'value', 'rangeType']);
export const getDateRangeFilterUseFiscalYear = config => config.getIn(['filters', 'dateRange', 'useFiscalYear']);
export const getFilterGroups = config => config.getIn(['filters', 'filterGroups']) || ImmutableList();
export const getQuery = config => config.getIn(['filters', 'query']);
export const getCompare = config => config.get('compare');
export const getUseFiscalYearInAggregation = config => config.get('useFiscalYearInAggregation');

/** displayParams */
export const getDisplayParams = report => report.get('displayParams', ImmutableMap({}));
export const getDisplayParamsColumns = (report, opts) => {
  const {
    excludeEmpty
  } = opts || {};
  const allColumns = getDisplayParams(report).get('columns');
  let filteredColumns = allColumns;
  if (allColumns && excludeEmpty) {
    var _allColumns$filter;
    filteredColumns = (_allColumns$filter = allColumns.filter(value => {
      return !(value !== null && value !== void 0 && value.isEmpty());
    })) === null || _allColumns$filter === void 0 ? void 0 : _allColumns$filter.toMap();
  }
  return filteredColumns;
};
export const getDisplayParamsDataColumns = report => getDisplayParams(report).get('dataColumns');
export const getDisplayParamsAllowDrilldown = report => getDisplayParams(report).get('allowDrilldown');
export const getReportProcessors = report => report.getIn(['config', 'processors'], ImmutableList([]));
export const getDisplayParamsQuickFilterProperties = report => getDisplayParams(report).get('quickFilterProperties', ImmutableList([]));
export const getDisplayParamsColorTheme = report => report.getIn(['displayParams', 'colors', 'theme']);
export const getDisplayParamsSubAppKey = report => report.getIn(['displayParams', 'salesAnalytics', 'subAppKey']);
export const getDisplayParamsShowTotals = report => getDisplayParams(report).get('showTotals');
export const getDisplayParamsHideMarkers = report => getDisplayParams(report).get('hideMarkers');
export const getDisplayParamsHideDataLabels = report => getDisplayParams(report).get('hideDataLabels');
export const getDisplayParamsShowTotalsColumn = report => getDisplayParams(report).get('showTotalsColumn');
export const getDisplayParamsIncludeDateLine = report => getDisplayParams(report).get('includeDateLine');
export const getConfigReportLimit = report => getReportConfig(report).get('limit');
export const getStaticGoal = report => {
  const displayParams = getDisplayParams(report);
  const customSeries = displayParams === null || displayParams === void 0 ? void 0 : displayParams.get('customSeries');
  const staticGoal = customSeries === null || customSeries === void 0 ? void 0 : customSeries.find(series => {
    if (series instanceof ImmutableMap) {
      return series.get('type') === DATETIME_GOAL;
    }
    return false;
  });
  return staticGoal === null || staticGoal === void 0 ? void 0 : staticGoal.get('goal');
};