import { getFullUrl } from 'hubspot-url-utils';
import PortalIdParser from 'PortalIdParser';
export const getAccountAndBillingUrl = portalId => {
  const baseUrl = getFullUrl('app');
  const hubId = portalId || PortalIdParser.get();
  return `${baseUrl}/account-and-billing/${hubId}`;
};
const getBase64EncodedInvoiceIdString = invoiceIds => {
  if (!invoiceIds) {
    return '';
  }
  return btoa([].concat(invoiceIds).join(','));
};
export const getPayInvoiceButtonUrl = (portalId, invoiceIds) => `${getAccountAndBillingUrl(portalId)}/transactions?pay=${getBase64EncodedInvoiceIdString(invoiceIds)}`;
export const goToPage = (portaId, pageName) => `${getAccountAndBillingUrl(portaId)}/${pageName}`;