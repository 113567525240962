export const localStorage = {
  getItem: key => {
    try {
      return window.localStorage.getItem(key);
    } catch (error) {
      return null;
    }
  },
  setItem: (key, value) => {
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      // ignore error
    }
  },
  removeItem: key => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      // ignore error
    }
  }
};