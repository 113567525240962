import { registerQuery, useQuery } from 'data-fetching-client';
import enviro from 'enviro';
import { useEffect } from 'react';
import { noAuthHttpClientWithRaven } from '../clients/hubHttpWithRaven';
import { OUTAGE_REASONS } from '../constants/outageReasons.constants';
import { logError } from '../utils/logError';
import { getDataFetchingClient } from '../clients/dataFetchingClient';
const defaultState = {
  zuora: {
    isDown: false,
    outageReason: null,
    outageStartTime: null,
    outageEndTime: null
  }
};
const getIsDown = response => {
  var _response$down;
  const isDown = (_response$down = response === null || response === void 0 ? void 0 : response.down) !== null && _response$down !== void 0 ? _response$down : false;
  if (typeof isDown !== 'boolean') {
    const message = `Expected isDown to be a boolean, received ${typeof isDown}`;
    logError({
      error: new Error(message)
    });
    return false;
  }
  return isDown;
};
const getOutageReason = response => {
  var _response$reason;
  const isDown = getIsDown(response);
  if (!isDown) {
    return null;
  }
  const reason = (_response$reason = response === null || response === void 0 ? void 0 : response.reason) !== null && _response$reason !== void 0 ? _response$reason : null;
  if (!reason) {
    return OUTAGE_REASONS.UNPLANNED_OUTAGE;
  }
  if (![OUTAGE_REASONS.PLANNED_OUTAGE, OUTAGE_REASONS.UNPLANNED_OUTAGE].includes(reason)) {
    const message = `Expected ${OUTAGE_REASONS.PLANNED_OUTAGE} or ${OUTAGE_REASONS.UNPLANNED_OUTAGE} as outage reason, got ${reason}`;
    logError({
      error: new Error(message)
    });
    return OUTAGE_REASONS.UNPLANNED_OUTAGE;
  }
  return reason;
};
const getOutageTimestamp = (response, key) => {
  var _response$key;
  const isDown = getIsDown(response);
  if (!isDown) {
    return null;
  }
  const timestamp = (_response$key = response === null || response === void 0 ? void 0 : response[key]) !== null && _response$key !== void 0 ? _response$key : null;
  if (timestamp === null) {
    return null;
  }
  if (typeof timestamp !== 'number' || timestamp <= 0) {
    const message = `Expected outage ${key} to be a valid timestamp`;
    logError({
      error: new Error(message)
    });
    return null;
  }
  return timestamp;
};
const mapResponse = response => {
  return {
    isDown: getIsDown(response),
    outageReason: getOutageReason(response),
    outageStartTime: getOutageTimestamp(response, 'startTime'),
    outageEndTime: getOutageTimestamp(response, 'endTime')
  };
};
const FintechOutagesZuoraQuery = registerQuery({
  fieldName: 'fintech_outages_zuora',
  args: ['hubId'],
  fetcher({
    hubId
  }) {
    const isProd = enviro.isProd();
    const suffix = isProd ? '' : `?hubId=${hubId}`;
    return noAuthHttpClientWithRaven.get(`/fintech-outages/v1/zuora${suffix}`, {
      ravenOptions: {
        message: 'Error fetching fintech outage status'
      }
    });
  }
});
let isPolling = false;
export const useZuoraAvailability = ({
  hubId
}) => {
  const {
    data,
    startPolling,
    stopPolling
  } = useQuery(FintechOutagesZuoraQuery, {
    variables: {
      hubId
    },
    client: getDataFetchingClient()
  });
  useEffect(() => {
    if (!isPolling) {
      startPolling(120 * 1000);
      isPolling = true;
    }
    return () => {
      stopPolling();
      isPolling = false;
    };
  }, [startPolling, stopPolling]);
  return data ? {
    zuora: mapResponse(data.fintech_outages_zuora)
  } : defaultState;
};