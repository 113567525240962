import { EDITOR_TYPES } from 'reporting-data/tsTypes/editorTypes';
import { isBaseReport } from 'reporting-data/tsTypes/reportTypes';
//@ts-expect-error untyped import
import CustomWidgets from 'reporting-ui-components/components/customWidgets/constants/widgets';
import { ReportOptionsCategories } from '../../singleReportViewer/fullPage/sidebar/categorized/ReportOptionCategory';
const editorOrder = [EDITOR_TYPES.METRIC, EDITOR_TYPES.PRESETS, EDITOR_TYPES.DIMENSION, EDITOR_TYPES.BREAKDOWN, EDITOR_TYPES.QUICK_FILTER, EDITOR_TYPES.PIPELINE_STAGE_ORDER, EDITOR_TYPES.USER_ADDED_QUICK_FILTER];
const getSchemaOrderIndex = schema => {
  const index = editorOrder.indexOf(schema.type);
  return index === -1 ? editorOrder.length : index;
};
const sortEditorSchemas = (schemaA, schemaB) => {
  const schemaAOrderIndex = getSchemaOrderIndex(schemaA);
  const schemaBOrderIndex = getSchemaOrderIndex(schemaB);
  return schemaAOrderIndex - schemaBOrderIndex;
};
function getUnsortedEditorSchemasFromConfig(report) {
  const editors = isBaseReport(report) && report.get('editors');
  return editors ? editors.toJS() : [];
}
export function getEditorSchemasFromConfig(report) {
  const schemas = getUnsortedEditorSchemasFromConfig(report);
  return schemas.sort(sortEditorSchemas);
}
export function getEditorSchemasMapFromConfig(report) {
  return getUnsortedEditorSchemasFromConfig(report).reduce((schemaMap, schema) => {
    schemaMap[schema.type] = schema;
    return schemaMap;
  }, {});
}
export function getEditorSchemaByType(report, type) {
  return getEditorSchemasMapFromConfig(report)[type];
}
export const getCustomWidgetExcludedFields = customWidgetType => {
  if (customWidgetType === CustomWidgets.COMPLETED_ACTIVITIES) {
    return ['hs_engagement_type'];
  }
  return [];
};
export function editorMatchesCategory(editor, category) {
  if (!category) {
    return true;
  }
  if (category === ReportOptionsCategories.More) {
    // More category includes all editors that don't have a category
    return editor.category === category || editor.category === undefined;
  }
  return editor.category === category;
}