'use es6';

import * as DSAssetFamilies from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilies';
import * as ObjectTypes from 'customer-data-objects/constants/ObjectTypes';
import { DSAssetFamilyToObjectType } from 'customer-data-filters/filterQueryFormat/DSAssetFamilies/DSAssetFamilyMappings';
import { ObjectTypeFromIds, ObjectTypesToIds } from 'customer-data-objects/constants/ObjectTypeIds';
import { isCustomBehavioralEventObjectTypeId, isUnifiedEventObjectTypeId, ObjectTypeIdRegex } from 'customer-data-filters/filterQueryFormat/ObjectTypeId';
import { getDynamicFilterFamilyBasis } from '../filterQueryFormat/DynamicFilterFamilies';
import devLogger from 'react-utils/devLogger';
const ObjectTypeIdAllowlist = [ObjectTypesToIds.ATTRIBUTION, ObjectTypesToIds.CONTACT_CREATE_ATTRIBUTION, ObjectTypesToIds.FEEDBACK_SUBMISSION];
const FilterFamilyNameAllowlist = [
// families that are not in Cerebro, note that property families are
// convertered to object types in dsAssetFamilyToReadableString.
DSAssetFamilies.ADS_PROPERTY, DSAssetFamilies.CTA, DSAssetFamilies.EMAIL_CAMPAIGN, DSAssetFamilies.EMAIL_SUBSCRIPTION, DSAssetFamilies.EVENT, DSAssetFamilies.FORM, DSAssetFamilies.CONTACT_IMPORT, DSAssetFamilies.CONTACT_LIST, DSAssetFamilies.IN_LIST, DSAssetFamilies.IMPORT, DSAssetFamilies.PAGE_VIEW, DSAssetFamilies.SURVEY_MONKEY_SURVEY, DSAssetFamilies.SURVEY_MONKEY_QUESTION, DSAssetFamilies.GOTO_WEBINAR_WEBINAR, DSAssetFamilies.WORKFLOW, DSAssetFamilies.TASK_PROPERTY, DSAssetFamilies.INTEGRATION, DSAssetFamilies.CUSTOM_BEHAVIORAL_EVENT,
// Analytics Settings filterFamily (#analytics-tools)
'VIEW',
// Reporting (#reporting-dev)
'ENGAGEMENT_LEGACY'];
export const legacyObjectTypes = [ObjectTypes.ATTRIBUTION, ObjectTypes.COMPANY, ObjectTypes.CONTACT, ObjectTypes.CONTACT_CREATE_ATTRIBUTION, ObjectTypes.DEAL, ObjectTypes.TICKET, ObjectTypes.ENGAGEMENT, ObjectTypes.LINE_ITEM, ObjectTypes.PRODUCT, ObjectTypes.QUOTE, ObjectTypes.FEEDBACK_SUBMISSION, ObjectTypes.TASK, ObjectTypes.GOAL_TARGET];
const isCrmObjectFilterFamily = filterFamilyName => legacyObjectTypes.includes(filterFamilyName) || ObjectTypeIdRegex.test(filterFamilyName);
export const getFilterFamilyName = filterFamily => {
  if (DSAssetFamilyToObjectType.has(filterFamily)) {
    return DSAssetFamilyToObjectType.get(filterFamily);
  }
  if (ObjectTypeIdAllowlist.includes(filterFamily)) {
    if (ObjectTypeFromIds[filterFamily]) {
      return ObjectTypeFromIds[filterFamily];
    }
  }
  return filterFamily;
};
export const getTranslationOptions = ({
  filterFamily,
  getFilterFamilyEntityName
}) => {
  const filterFamilyBasis = getDynamicFilterFamilyBasis(filterFamily);
  if (isCrmObjectFilterFamily(filterFamily) || filterFamilyBasis === DSAssetFamilies.INTEGRATION || filterFamilyBasis === DSAssetFamilies.UNIFIED_EVENT) {
    const entityName = getFilterFamilyEntityName(filterFamily);
    if (entityName) {
      return {
        entityName
      };
    }
    devLogger.warn({
      message: `Filter Family Entity Name must be provided and was not found for ${filterFamily}`
    });
    return {
      entityName: filterFamily
    };
  }
  return undefined;
};
export const getDefaultTranslationKey = filterFamily => {
  const dynamicFilterFamilyBasis = getDynamicFilterFamilyBasis(filterFamily);
  const effectiveFilterFamily = getFilterFamilyName(dynamicFilterFamilyBasis || filterFamily);
  if (dynamicFilterFamilyBasis === DSAssetFamilies.UNIFIED_EVENT) {
    return 'UNIFIED_EVENT';
  }
  if (isUnifiedEventObjectTypeId(effectiveFilterFamily)) {
    return 'UNIFIED_EVENT_TYPE';
  }
  if (isCustomBehavioralEventObjectTypeId(effectiveFilterFamily)) {
    return 'CUSTOM_BEHAVIORAL_EVENT_TYPE';
  }
  if (isCrmObjectFilterFamily(effectiveFilterFamily)) {
    return 'CRM_OBJECT';
  }
  return FilterFamilyNameAllowlist.find(allowListedFamily => allowListedFamily === effectiveFilterFamily);
};